import { z } from "zod"

type Display = {
	sku: string
	properName: string
	height: number
	width: number
	aspectRatio: number
}

//full list of all our products
export const Displays: Display[] = [
	{ sku: "LKG-E", properName: "Looking Glass Go", height: 2560, width: 1440, aspectRatio: 0.5625 },
	{ sku: "LKG-P", properName: "Looking Glass Portrait", height: 2048, width: 1536, aspectRatio: 0.75 },
	{
		sku: "LKG-J",
		properName: "Looking Glass 16 Spatial Display (Landscape)",
		width: 3840,
		height: 2160,
		aspectRatio: 1.778,
	},
	{
		sku: "LKG-H",
		properName: "Looking Glass 16 Spatial Display (Portrait)",
		width: 3840,
		height: 2160,
		aspectRatio: 0.5625,
	},
	{
		sku: "LKG-L",
		properName: "Looking Glass 32 Spatial Display (Landscape)",
		height: 2160,
		width: 3840,
		aspectRatio: 1.778,
	},
	{
		sku: "LKG-K",
		properName: "Looking Glass 32 Spatial Display (Portrait)",
		height: 3840,
		width: 2160,
		aspectRatio: 0.5625,
	},
	{
		sku: "LKG-2K",
		properName: `Looking Glass 8.9" Legacy`,
		height: 1600,
		width: 2560,
		aspectRatio: 1.6,
	},
]

export const validAspectRatios = [
	0.5625, // Looking Glass Go | Looking Glass Spatial Display (Portrait)
	0.75, // Looking Glass Portrait
	1.778, // Looking Glass Spatial Display (Landscape)
	1.6, // Looking Glass 8.9" (Legacy)
] as const

/**
 * compareFloats -- used in the DisplayDropDown and DisplayDrawers components
 * one fun thing with our displays is that the 16:9 aspect ratio is
 * 1.7777777 repeating, sometimes this is shortened to 1.77 or 1.778
 * in order to best handle this, we introduce a `compareFloats` function
 * which handles small inequalities like these.
 */
export function compareFloats(a: number, b: number, delta: number = 0.001) {
	if (Math.abs(a - b) < delta) return true
	return false
}

// compare an input aspect ratio with our known list of constants
export function findValidAspectRatio(givenAspectRatio: number): 0.5625 | 0.75 | 1.6 | 1.778 | null {
	for (const validRatio of validAspectRatios) {
		if (compareFloats(givenAspectRatio, validRatio)) {
			return validRatio
		}
	}
	return null
}

// a zod type, allowing us to more strictly define our API inputs.
export const displayAspects = z.union([z.literal(0.5625), z.literal(0.75), z.literal(1.778), z.literal(1.6)])
