/** If true, the settings page will allow you to clear your username. */
export const ALLOW_BLANK_USERNAME_FOR_TESTING = !!process.env.ALLOW_BLANK_USERNAME_FOR_TESTING

export const RESERVED_USERNAMES = [
	"api",
	"admin",
	"playlists",
	"invite",
	"embed",
	"404",
	"500",
	"discover",
	"library",
	"manage",
	"settings",
	"upload",
]

export const USERNAME_MIN_LENGTH = 3
export const USERNAME_MAX_LENGTH = 20
export const USERNAME_REGEX = new RegExp(/^(?!\d+$)[a-zA-Z0-9-_]{3,20}$/, "i")

export function validateUsername(username: string): boolean {
	if (ALLOW_BLANK_USERNAME_FOR_TESTING) return true
	return username?.match(USERNAME_REGEX) != null
}

export const MAX_UPLOAD_BYTES = 1024 * 1024 * 300 // up to 3MB
export const MAX_VIDEO_UPLOAD_BYTES = 1024 * 1024 * 1024 // up to 1GB
export const MAX_UPLOAD_PROFILE_PICTURE_BYTES = 1024 * 1024 * 5 // up to 2MB

export const MAX_TITLE_LENGTH = 140
