import { cloneDeep } from "@apollo/client/utilities"
import {
	FeatureFlagKey,
	ImageAsset,
	Prisma,
	Hologram as PrismaHologram,
	Privacy,
	UserRole,
} from "@prisma/client"
import { Context } from "graphql/context"
import { Hologram, User } from "../graphql/client/gql/types"
import {
	findSourceImage,
	HOLOGRAM_DEFAULT_ASPECT_RATIO,
	HOLOGRAM_DEFAULT_QUILT_COLS,
	HOLOGRAM_DEFAULT_QUILT_ROWS,
	HOLOGRAM_DEFAULT_QUILT_ZOOM,
	HOLOGRAM_DEFAULT_RGBD_DEPTHINESS,
	HOLOGRAM_DEFAULT_RGBD_FOCUS,
	HOLOGRAM_DEFAULT_RGBD_ZOOM,
	HOLOGRAM_DEFAULT_TILE_COUNT,
} from "../prisma/models/Holograms.model"
import { getCDNUrl } from "./cdn"
import { EmbedHologram, EmbedImageAsset } from "./hologramProps"
import { toQueryString } from "./utils.embed"
import { getUsernameOrUUID, GetUserUsernameOptions } from "./utils.user"

export interface ImageAsset_UrlWidthHeight extends Required<Pick<ImageAsset, "url" | "width" | "height">> {}
export interface ImageAsset_UrlWidthHeightType
	extends Required<Pick<ImageAsset, "url" | "width" | "height" | "type">> {}

export const PrivacyDesc = {
	PUBLIC: "Public",
	UNLISTED: "Unlisted",
	ONLY_ME: "Private",
}

const hologramWithAll = Prisma.validator<Prisma.HologramArgs>()({
	include: {
		imageAssets: true,
		user: {
			include: { avatar: true },
		},
		Settings: true,
	},
})

const hologramWithUser = Prisma.validator<Prisma.HologramArgs>()({
	include: {
		user: true,
		Settings: true,
	},
})

export interface PrismaHologramWithUser extends Prisma.HologramGetPayload<typeof hologramWithUser> {}

export function getSourceImages(hologram: EmbedHologram) {
	return hologram.imageAssets
		.filter(
			// Remove image assets that are either gifs or have a kind of RGBD, DEPTH, or GENERATED_QUILT
			({ type, kind }) => !/video|gif/g.test(type) && !/RGBD|DEPTH|GENERATED_QUILT/g.test(kind),
		)
		.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
}

export function getHologramDefaults(hologram: EmbedHologram) {
	if (!hologram) return hologram

	return {
		...hologram,
		canUsersDownload: hologram.canUsersDownload,
		aspectRatio: hologram.aspectRatio ?? HOLOGRAM_DEFAULT_ASPECT_RATIO,
		quiltCols: hologram.type === "RGBD" ? 10 : (hologram.quiltCols ?? HOLOGRAM_DEFAULT_QUILT_COLS),
		quiltRows: hologram.type === "RGBD" ? 6 : (hologram.quiltRows ?? HOLOGRAM_DEFAULT_QUILT_ROWS),
		quiltTileCount: hologram.quiltTileCount ?? HOLOGRAM_DEFAULT_TILE_COUNT,
		rgbdDepthiness: hologram.rgbdDepthiness ?? HOLOGRAM_DEFAULT_RGBD_DEPTHINESS,
		rgbdFocus: hologram.rgbdFocus ?? HOLOGRAM_DEFAULT_RGBD_FOCUS,
		rgbdZoom:
			hologram.rgbdZoom ??
			(hologram.type === "RGBD" ? HOLOGRAM_DEFAULT_RGBD_ZOOM : HOLOGRAM_DEFAULT_QUILT_ZOOM),
		rgbdStretch: hologram.rgbdStretch ?? 1,
		...(hologram.imageAssets
			? {
					sourceImages: getSourceImages(hologram),
					previewVideoAssets: hologram.imageAssets.filter(({ type }) => type.startsWith("video/")),
					previewGifAssets: hologram.imageAssets.filter(({ type }) => type === "image/gif"),
					permalink: hologram.user ? getPermalinkUrl(hologram.user, hologram) : undefined,
					thumbnail: getThumbnail(hologram, 1000) ?? null,
				}
			: {}),
	}
}

interface IHologramSettingsPath {
	user: GetUserUsernameOptions
	id: number
}
export function getHologramSettingsPath(hologram: IHologramSettingsPath, redirect?: string) {
	return `/${getUsernameOrUUID(hologram.user)}/${hologram?.id}`
}

// TODO make this smarter / account for more aspect ratios
export function getAspectRatioClassName(aspect: number | null | undefined) {
	if (!aspect) {
		return "aspect-w-16 aspect-h-9"
	}
	if (aspect < 1) {
		return "aspect-h-4 aspect-w-3"
	} else if (aspect == 1) {
		return "aspect-w-1 aspect-h-1"
	} else {
		return "aspect-w-16 aspect-h-9"
	}
}

export interface HologramPermalinkOptions extends Required<Pick<Hologram, "id">> {
	uuid?: string | null
	privacy: Hologram["privacy"] | Privacy
}

export function getPermalinkPath(
	user: GetUserUsernameOptions,
	hologram: HologramPermalinkOptions,
	query?: any,
) {
	let querystring = toQueryString(query)
	if (querystring) querystring = "?" + querystring

	if (hologram.privacy && hologram.privacy == "UNLISTED") {
		return `/${getUsernameOrUUID(user)}/${hologram.uuid}${querystring}`
	} else {
		return `/${getUsernameOrUUID(user)}/${hologram.id}${querystring}`
	}
}

export function getPermalinkUrl(
	user: GetUserUsernameOptions,
	hologram: HologramPermalinkOptions,
	query?: any,
) {
	return process.env.BASE_URL + getPermalinkPath(user, hologram, query)
}

interface BlurredBackgroundImage extends Pick<Hologram, "quiltTileCount">, QuiltImageAngleHologram {
	sourceImages: QuiltImageAngleSourceImage[]
}

export function getBlurredBackgroundImage(hologram: EmbedHologram) {
	const sourceImage = findSourceImage(hologram)
	if (!sourceImage) return null
	return getImageAngleFromQuilt(hologram, sourceImage, Math.round(hologram.quiltTileCount! / 2), 2000, {
		blur: 1100,
		bri: -30,
		h: 2000,
	})
}

interface AdaptiveSizedQuilts {
	sourceImages: { width: number; url: string; height: number }[]
}
export function getAdaptiveSizedQuilts(hologram: AdaptiveSizedQuilts) {
	const sourceImage = hologram.sourceImages?.[0]

	if (!sourceImage) {
		console.warn("No source image found")
		return []
	}

	let quiltSizes = [1000]
	while (quiltSizes[quiltSizes.length - 1] + 500 < sourceImage.width) {
		quiltSizes.push(quiltSizes[quiltSizes.length - 1] + 500)
	}

	quiltSizes.push(sourceImage.width!)

	const aspect = sourceImage.width / sourceImage.height

	return quiltSizes
		.map((size) => {
			const height = Math.round(size / aspect)
			const url = getCDNUrl(sourceImage.url, { width: size, height, format: "webp" })

			return { ...sourceImage, url, width: size, height }
		})
		.filter((quilt) => quilt != null)
}

interface BlurredImageType extends Pick<ImageAsset, "url" | "width" | "height"> {}
export function getBlurredImagePreview<T extends BlurredImageType | null | undefined>(image: T): T | null {
	if (!image || !image.url) {
		console.warn("No image url found")
		return null
	}
	const img = cloneDeep(image) as T
	if (!img) return null
	img.url += "&blur=200"

	return img
}

export interface IsOwnerHologramParam {
	userId: number
	user?: { id: number }
}
export function isOwner(user: EditPermissionUserParam, hologram: IsOwnerHologramParam): boolean {
	let userId
	if ("user" in hologram) {
		userId = hologram.user?.id
	} else if ("userId" in hologram) {
		userId = hologram.userId
	}

	if (userId == undefined) {
		throw new Error("isOwner: hologram.userId and .user.id are undefined")
	}
	return user && user.id == userId
}

interface IViewableHologram extends Pick<Hologram, "isPublished" | "uuid">, IsOwnerHologramParam {
	privacy: Hologram["privacy"] | Privacy
}
export function isViewable(
	user: EditPermissionUserParam | null | undefined,
	uuid: string | null | undefined,
	hologram: IViewableHologram | null | undefined,
): boolean {
	if (!hologram) return false

	const hasPermission = hasEditPermission(user, hologram)

	if (!hologram.isPublished && !hasPermission) return false

	return (
		hologram.privacy == "PUBLIC" ||
		(hologram.privacy == "UNLISTED" && (uuid == hologram.uuid || hasPermission)) ||
		(hologram.privacy == "ONLY_ME" && hasPermission)
	)
}

interface EditPermissionUserParam {
	role?: User["role"] | UserRole
	id: number
}

export function isEditor(user: Context["user"], hologram: { id: number; user: { id: number } }) {
	return (
		user?.role == "ADMIN" ||
		user?.id == hologram.user.id ||
		user?.UserFeatureFlag?.find((f) => f.flag == "HOLOGRAMS_UPDATE_ALL")
	)
}
export function hasEditPermission(
	user?: EditPermissionUserParam | null,
	hologram?: IsOwnerHologramParam | null,
	flags?: FeatureFlagKey[],
): boolean {
	if (!user || !hologram) return false
	const hasUpdateAllFlag = flags?.includes("HOLOGRAMS_UPDATE_ALL") ?? false
	return user?.role == "ADMIN" || isOwner(user, hologram) || hasUpdateAllFlag
}

export function canAddToPlaylist(user?: EditPermissionUserParam | null, hologram?: Hologram | null) {
	if (!user || !hologram) return false

	if (hologram.privacy === "PUBLIC") {
		return true
	}

	if (user.id === hologram.user.id) {
		return true
	}
}

export interface HologramEditPermission {
	privacy: Hologram["privacy"] | Privacy
	isPublished: boolean
}

export function isShareable(hologram: HologramEditPermission) {
	return hologram.privacy != "ONLY_ME" && hologram.isPublished
}

interface QuiltByLenticularizedWidthProps extends Pick<Hologram, "quiltCols"> {
	sourceImages: { url: string; width: number; height: number }[]
}

export function getQuiltByPreferredLenticularizedWidth(
	hologram: QuiltByLenticularizedWidthProps,
	width: number,
	maxTextureSize = 16384,
): string | null {
	const quilts = getAdaptiveSizedQuilts(hologram)

	// If no quilts, then just return source url :(
	if (quilts?.length == 0) {
		return hologram.sourceImages[0].url
	}
	return getQuiltByWidth(quilts, width * hologram.quiltCols!, maxTextureSize)
}

export type ClosestImageAssetType = Required<Pick<ImageAsset, "width">>
/** Find the closest image given the preferred width */
export function getClosestImage(images: EmbedImageAsset[], width: number) {
	if (images == undefined) {
		console.error("getClosestImage: images is undefined")
		return null
	}

	const widths = images.map((q) => q.width)

	if (widths.length == 0) {
		return null
	}

	var closestWidth = widths.reduce(function (prev, curr) {
		return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev
	})

	return images.find((q) => q.width == closestWidth) ?? null
}

export function getQuiltByWidth(
	quilts: Partial<ImageAsset> & { width: number; height: number }[],
	width: number,
	maxTextureSize = 16384,
): string | null {
	// This is all WebGL specific logic which maybe could go away with HTML only
	const maxSize = Math.min(7000, maxTextureSize)
	quilts = quilts.filter((q) => !(q.width > maxSize || q.height > maxSize))

	if (quilts.length == 0) {
		return null
	}

	const image = getClosestImage(quilts as EmbedImageAsset[], width)

	if (!image) return null
	else return image.url ?? null
}

interface TotalAnglesArgs
	extends Partial<Pick<PrismaHologram, "quiltCols" | "quiltRows" | "quiltTileCount">> {}

export function getTotalAngles(hologram: TotalAnglesArgs) {
	const rows = hologram.quiltRows || HOLOGRAM_DEFAULT_QUILT_ROWS
	const cols = hologram.quiltCols || HOLOGRAM_DEFAULT_QUILT_COLS
	return !!hologram.quiltTileCount && hologram.quiltTileCount > 0 ? hologram.quiltTileCount : cols * rows
}

export function getThumbnail<T extends EmbedImageAsset>(
	hologram: EmbedHologram & { imageAssets: T[] },
	width: number = 1200,
) {
	const source = findSourceImage(hologram)

	if (!source) return null

	if (hologram.type == "QUILT") {
		return getImageAngleFromQuilt(
			hologram,
			source,
			Math.round((hologram.quiltTileCount ?? HOLOGRAM_DEFAULT_TILE_COUNT) / 2),
			width,
			{
				fit: "scale",
				fm: "jpg",
				auto: null,
			},
		)
	} else if (hologram.type == "RGBD") {
		return getThumbnailFromRGBD(source, width)
	}
	return null
}

export function getThumbnailFromRGBD<T extends EmbedImageAsset>(source: T, width: number = 1200) {
	const aspectRatio = source.width / source.height
	let imgHeight = Math.round(width / aspectRatio!)
	if (imgHeight % 2) {
		imgHeight += 1 // height should always be even
	}

	const url = getCDNUrl(source.url, { fit: "cover", height: imgHeight, width, format: "webp" })
	const type = "image/webp"

	return { ...source, height: imgHeight, url, width, type }
}

export interface QuiltImageAngleHologram extends Pick<Hologram, "quiltCols" | "quiltRows" | "aspectRatio"> {}

export interface QuiltImageAngleSourceImage extends Pick<ImageAsset, "url" | "width" | "height"> {}

export function getImageAngleFromQuilt<T extends EmbedImageAsset>(
	hologram: EmbedHologram,
	sourceImage: T,
	angle: number,
	width: number,
	cdnArgs: any = {},
) {
	const rows = hologram.quiltRows || HOLOGRAM_DEFAULT_QUILT_ROWS
	const cols = hologram.quiltCols || HOLOGRAM_DEFAULT_QUILT_COLS
	const aspectRatio = hologram.aspectRatio || HOLOGRAM_DEFAULT_ASPECT_RATIO

	// Dont fetch images that are larger than the original lenticularized resolution
	const lenticularWidth = Math.round(sourceImage.width / hologram.quiltCols!)
	if (width > lenticularWidth) {
		width = lenticularWidth
	}

	const w = Math.round(sourceImage.width / cols)
	const h = Math.round(sourceImage.height / rows)

	const idx = angle

	const xPos = (idx % cols) * w
	const yPos = sourceImage.height - (Math.floor(idx / cols) + 1) * h

	let imgHeight = Math.round(width / aspectRatio)
	if (imgHeight % 2) {
		imgHeight += 1 // height should always be even
	}

	const format = cdnArgs.fmt === "jpg" ? "jpeg" : !!cdnArgs.fmt ? cdnArgs.fmt : "webp"

	const rect = { x: xPos, y: yPos, w, h }
	const url = getCDNUrl(sourceImage.url, { width, height: imgHeight, format, rect })
	const type = `image/${cdnArgs.fm || "webp"}`

	return { ...sourceImage, height: imgHeight, url, width, type }
}
