import {
	PencilIcon,
	LockClosedIcon,
	LinkIcon,
	PlusIcon,
	CodeBracketIcon,
	ArrowUpOnSquareIcon,
	FlagIcon,
	TrashIcon,
} from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { classNames } from "lib/classNames"
import { EyeSlashIcon, GlobeAmericasIcon } from "@heroicons/react/24/solid"
import { Tooltip } from "components/Tooltip"
import { toast } from "react-hot-toast"
import { getHologramSettingsPath, getPermalinkUrl } from "lib/utils.hologram"
import { getEmbedCode } from "lib/utils.embed"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import { hasEditPermission } from "lib/utils.hologram"
import useUpdateHolograms from "hooks/useUpdateHolograms"
import Popup from "components/Popup"
import RadioIcon from "components/RadioButton"
import HologramThumbnail from "components/HologramThumbnail"
import { UserProfileImage } from "components/UserProfileImage"
import AddHologramToPlaylistMobilePopup from "./playlists/Popups/AddHologramToPlaylistMobile"
import { FlagHologram } from "./moderation/FlagHologramPopup"
import { useRouter } from "next/router"
import { toastSuccessStyle } from "./toastStyles"
import { useBlocksUserStore } from "@/hooks/useStore"
import { EmbedHologram } from "@/lib/hologramProps"

type HologramPrivacy = "PUBLIC" | "UNLISTED" | "ONLY_ME"

interface HologramSettingsPopupArgs {
	open: boolean
	setOpen: (open: boolean) => void
	hologram: EmbedHologram
	privacy: HologramPrivacy
	setPrivacy: (privacy: HologramPrivacy) => void
	setDownloadPopup(hologram: EmbedHologram): void
	setSharePopup(hologram: EmbedHologram): void
	setFlagPopup(hologram: FlagHologram): void
	setDeletePopup(id: number[]): void
}

export default function HologramSettingsPopup(args: HologramSettingsPopupArgs) {
	const { updateHologramPrivacy, updateHologramDownloadable } = useUpdateHolograms()
	const [canUsersDownload, setCanUsersDownload] = useState<boolean>(args.hologram.canUsersDownload)

	const handlePrivacyChange = async (newPrivacy: HologramPrivacy) => {
		const currentPrivacy = args.privacy
		args.setPrivacy(newPrivacy) // Optimistic update
		try {
			const updatedPrivacy = await updateHologramPrivacy(args.hologram.id, newPrivacy) // Update on server
			toast(`Updated Hologram to ${updatedPrivacy}`)
			// Additional success handling if needed
		} catch (error) {
			// Revert to previous state on error
			args.setPrivacy(currentPrivacy)
			console.error("Failed to update privacy", error)
			toast("Oh no! an error occured 😱")
		}
	}

	const toggleCanUsersDownload = async () => {
		try {
			setCanUsersDownload(!canUsersDownload)
			const updateCanUsersDownload = await updateHologramDownloadable(args.hologram.id, !canUsersDownload)
			toast(
				updateCanUsersDownload
					? `Users can now download this hologram`
					: `Users can no longer download this hologram`,
			)
		} catch (error) {
			console.error("Failed to update downloadable", error)
			toast("Oh no! an error occured 😱")
		}
	}

	useEffect(() => {
		console.log(args.open)
	}, [args.open])

	return (
		<Popup
			className="pointer-events-none z-20 max-h-[80dvh] max-w-xl overflow-hidden"
			initialFocus={false}
			open={args.open}
			onClose={() => args.setOpen(false)}
			title={"Settings"}>
			<Content
				privacy={args.privacy}
				handlePrivacyChange={handlePrivacyChange}
				canUsersDownload={canUsersDownload}
				toggleCanUsersDownload={toggleCanUsersDownload}
				hologram={args.hologram}
				setDownloadPopup={args.setDownloadPopup}
				setSharePopup={args.setSharePopup}
				setFlagPopup={args.setFlagPopup}
				setDeletePopup={args.setDeletePopup}
			/>
		</Popup>
	)
}

function Content({ ...args }) {
	// handle link click
	function onLinkClick(url: string) {
		window.open(url, "_blank", "noreferrer")
	}

	const [copiedText, setCopy] = useCopyToClipboard()

	const user = useBlocksUserStore((state) => state.dbUser)
	const isAdmin = useBlocksUserStore((state) => state.authUser)

	const router = useRouter()

	const canEditHologram = hasEditPermission(user, args.hologram)

	const [openSubMenu, setOpenSubMenu] = useState(false)

	const navigate = () => {
		router.push(getHologramSettingsPath(args.hologram, router.asPath))
	}

	return (
		<>
			<div>
				<div>
					<div className="z-50 flex grow-0 flex-row items-center justify-start gap-3 px-4 py-3">
						<div className="mx-2 h-14 w-14">
							{args.hologram && <HologramThumbnail hologram={args.hologram} width={56} height={56} />}
						</div>
						<div className="flex h-full max-w-sm grow flex-col justify-center gap-1 overflow-hidden">
							<p className="text-md overflow-hidden truncate whitespace-nowrap py-2 text-left font-semibold">
								{args.hologram.title}
							</p>
							<div className="flex flex-row gap-2">
								<UserProfileImage
									user={{
										id: args.hologram?.user.id,
										picture: args.hologram?.user?.avatar?.url,
										uuid: args.hologram?.user?.uuid,
									}}
									className="w-5"
								/>
								<p className="overflow-hidden truncate whitespace-nowrap text-left text-sm font-light">
									{args.hologram.user.username}
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* SHARE CONTENT */}
				<div className="my-2 h-[1px] bg-black/10 dark:bg-white/10" />
				<div className="px-4">
					{canEditHologram && (
						<div
							className="bg-opacity-0 px-2 py-4 hover:bg-white hover:bg-opacity-10"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation(), navigate()
							}}>
							<label className="text-md flex flex-row items-center gap-3 text-dark-purple-100 dark:text-white">
								<PencilIcon className="w-6 fill-dark-purple-100 dark:fill-white" />
								Edit
							</label>
						</div>
					)}
					{user && (
						<div className="bg-opacity-0 px-2 py-4 hover:bg-white hover:bg-opacity-10">
							<label
								className="text-md flex flex-row items-center gap-3 text-dark-purple-100 dark:text-white"
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setOpenSubMenu(true)
								}}>
								<PlusIcon className="w-6 stroke-dark-purple-100 stroke-[3] dark:stroke-white" />
								Add to playlist
							</label>
						</div>
					)}
					<div>
						<div
							className="bg-opacity-0 px-2 py-4 hover:bg-white hover:bg-opacity-10"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								args.setSharePopup(args.hologram)
							}}>
							<label className="text-md flex flex-row items-center gap-3">
								<ArrowUpOnSquareIcon className="w-6 stroke-dark-purple-100 stroke-2 dark:stroke-white" />
								Share
							</label>
						</div>
						<div className="bg-opacity-0 px-2 py-4 hover:bg-white hover:bg-opacity-10">
							<label
								className="text-md flex flex-row items-center gap-3"
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setCopy(getPermalinkUrl(args.hologram.user, args.hologram))
									toast.success("Copied to clipboard", toastSuccessStyle)
								}}>
								<LinkIcon className="w-6 stroke-dark-purple-100 stroke-2 dark:stroke-white" />
								Copy Link
							</label>
						</div>

						<div
							className="bg-opacity-0 px-2 py-4 hover:bg-white hover:bg-opacity-10"
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setCopy(getEmbedCode(args.hologram))
								toast.success("Copied to clipboard", toastSuccessStyle)
							}}>
							<label className="text-md flex flex-row gap-3">
								<CodeBracketIcon className="w-6 stroke-dark-purple-100 stroke-2 dark:stroke-white" />
								Copy Embed Code
							</label>
						</div>
					</div>
					{/* MANAGE CONTENT */}

					{args.hologram?.user.id !== user?.id && isAdmin && (
						<div
							className="hover: bg-red-500 bg-opacity-0 px-2 py-2 hover:bg-opacity-90"
							onClick={(e) => {
								e.stopPropagation()

								args.setFlagPopup(args.hologram)
							}}>
							<label className="text-md flex flex-row gap-3">
								<FlagIcon className="w-6 stroke-2" />
								Report hologram
							</label>
						</div>
					)}

					{canEditHologram && (
						<>
							<div className="my-2 h-[1px] bg-white opacity-5" />

							<div
								className="md: md: bg-white bg-opacity-0 px-2 py-6 text-red-500 hover:bg-opacity-10 hover:text-red-500"
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									args.setDeletePopup([args.hologram.id])
								}}>
								<label className="text-md flex flex-row gap-3">
									<TrashIcon className="w-6 stroke-2" />
									Delete Hologram
								</label>
							</div>
						</>
					)}
				</div>
			</div>
			<AddHologramToPlaylistMobilePopup
				open={openSubMenu}
				setOpen={setOpenSubMenu}
				hologram={args.hologram}
			/>
		</>
	)
}

interface PrivacyItemArgs {
	//current value
	privacy: string
	// label for the UI
	privacyLabel: string
	// value that the component should reflect
	privacyValue: string
	// description of the setting
	description: string
	// onclick
	onClick: () => void
}

function PrivacyItem({ ...args }: PrivacyItemArgs) {
	const { privacy, privacyLabel, privacyValue, description, onClick } = args

	return (
		<button
			className={classNames(
				"flex cursor-pointer flex-row items-center gap-3.5 rounded-lg px-2 py-4",
				`${
					privacyValue === privacy
						? "border-2 border-solid border-purple-500 bg-[rgba(160,85,250,0.20)]"
						: "hover:bg-white hover:bg-opacity-10"
				}`,
			)}
			onClick={onClick}>
			<RadioIcon selected={privacyValue === privacy} />
			{/* Public */}
			<Tooltip content={description} delayDuration={0}>
				<div className="items center flex flex-row gap-3">
					{privacyLabel === "Public" && <GlobeAmericasIcon className="w-6 dark:fill-[white]" />}
					{/* PRIVATE */}
					{privacyLabel === "Private" && <LockClosedIcon className="w-6 dark:fill-[white]" />}
					{/* UNLISTED */}
					{privacyLabel === "Unlisted" && <EyeSlashIcon className="w-6 dark:fill-[white]" />}

					<label className="text-md text-left" htmlFor="r1">
						{privacyLabel}
					</label>
				</div>
			</Tooltip>
		</button>
	)
}
