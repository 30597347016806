const Hologram = {
	id: 954,
	uuid: "e28648cc-3015-4dcc-bbc3-963e67783c04",
	title: "banana duct-taped to a wall",
	description: "hello world to portrait displace\nbanana asset by Lassi Kaukonen on sketchfab",
	createdAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
	updatedAt: new Date("Tue Jan 21 2025 12:17:26 GMT-0800 (Pacific Standard Time)"),
	privacy: "PUBLIC",
	userId: 10,
	appId: null,
	type: "QUILT",
	aspectRatio: 0.75,
	crop_pos_x: null,
	crop_pos_y: null,
	quiltCols: 8,
	quiltRows: 6,
	quiltTileCount: 48,
	rgbdDepthiness: 0.15,
	rgbdFocus: 0,
	rgbdZoom: 0.5,
	rgbdStretch: 1,
	isPublished: true,
	canAddToPlaylist: true,
	canUsersDownload: false,
	totalViews: 52,
	totalViews30d: 52,
	totalViews7d: 11,
	isShadowBan: false,
	user: {
		id: 10,
		uuid: "401e16e3-6cff-40ad-8cc7-f51c5011104c",
		displayName: "adrian",
		username: "adrian",
		avatar: null,
		picture: "/foil-avatar.jpg",
	},
	imageAssets: [
		{
			id: 19,
			url: "https://dl-dev.blocks.glass/u/cd2a90c6e0f04e79/954-banana_ducttapedtoawall-420.mp4",
			width: 420,
			height: 560,
			type: "video/mp4",
			kind: "NONE",
			fileSize: 130843,
			createdAt: new Date("Fri Jan 17 2025 10:45:41 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:45:41 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 18,
			url: "https://dl-dev.blocks.glass/u/c43d19697bf9423c/954-banana_ducttapedtoawall-350.mp4",
			width: 350,
			height: 466,
			type: "video/mp4",
			kind: "NONE",
			fileSize: 96662,
			createdAt: new Date("Fri Jan 17 2025 10:45:18 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:45:18 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 15,
			url: "https://dl-dev.blocks.glass/u/efb58e6ad1544448/954-banana_ducttapedtoawall-250.gif",
			width: 250,
			height: 333,
			type: "image/gif",
			kind: "NONE",
			fileSize: 1975529,
			createdAt: new Date("Fri Jan 17 2025 10:36:16 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:36:16 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 14,
			url: "https://dl-dev.blocks.glass/u/f0eef6cff8ed41fe/954-banana_ducttapedtoawall-350.gif",
			width: 350,
			height: 467,
			type: "image/gif",
			kind: "NONE",
			fileSize: 3725691,
			createdAt: new Date("Fri Jan 17 2025 10:35:52 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:35:52 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 11,
			url: "https://s3.amazonaws.com/lkg-blocks-dev/testing/banana.png",
			width: 3360,
			height: 3360,
			type: "image/png",
			kind: "SOURCE",
			fileSize: 16522967,
			createdAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
			versionHash: null,
		},
	],
	Settings: [],
	sourceImages: [
		{
			id: 11,
			url: "https://s3.amazonaws.com/lkg-blocks-dev/testing/banana.png",
			width: 3360,
			height: 3360,
			type: "image/png",
			kind: "SOURCE",
			fileSize: 16522967,
			createdAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
			versionHash: null,
		},
	],
	previewVideoAssets: [
		{
			id: 18,
			url: "https://dl-dev.blocks.glass/u/c43d19697bf9423c/954-banana_ducttapedtoawall-350.mp4",
			width: 350,
			height: 466,
			type: "video/mp4",
			kind: "NONE",
			fileSize: 96662,
			createdAt: new Date("Fri Jan 17 2025 10:45:18 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:45:18 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 19,
			url: "https://dl-dev.blocks.glass/u/cd2a90c6e0f04e79/954-banana_ducttapedtoawall-420.mp4",
			width: 420,
			height: 560,
			type: "video/mp4",
			kind: "NONE",
			fileSize: 130843,
			createdAt: new Date("Fri Jan 17 2025 10:45:41 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:45:41 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
	],
	previewGifAssets: [
		{
			id: 14,
			url: "https://dl-dev.blocks.glass/u/f0eef6cff8ed41fe/954-banana_ducttapedtoawall-350.gif",
			width: 350,
			height: 467,
			type: "image/gif",
			kind: "NONE",
			fileSize: 3725691,
			createdAt: new Date("Fri Jan 17 2025 10:35:52 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:35:52 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
		{
			id: 15,
			url: "https://dl-dev.blocks.glass/u/efb58e6ad1544448/954-banana_ducttapedtoawall-250.gif",
			width: 250,
			height: 333,
			type: "image/gif",
			kind: "NONE",
			fileSize: 1975529,
			createdAt: new Date("Fri Jan 17 2025 10:36:16 GMT-0800 (Pacific Standard Time)"),
			updatedAt: new Date("Fri Jan 17 2025 10:36:16 GMT-0800 (Pacific Standard Time)"),
			versionHash: "070fefc12e79c448d3cfb4f08263563f940767c3",
		},
	],
	permalink: "http://localhost:3000/adrian/954",
	thumbnail: {
		id: 11,
		url: "https://dl-dev.blocks.glass/testing/banana.png?format=webp&fit=fill&rect=0-1120-420-560&width=420&height=560",
		width: 420,
		height: 560,
		type: "image/jpg",
		kind: "SOURCE",
		fileSize: 16522967,
		createdAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
		updatedAt: new Date("Fri Jan 17 2025 10:35:15 GMT-0800 (Pacific Standard Time)"),
		versionHash: null,
	},
}
export default Hologram
