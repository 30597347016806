import { getCDNUrl } from "@/lib/cdn"
import Image from "next/image"
import { twMerge } from "tailwind-merge"

interface PreviewCardFooterArgs {
	title: string
	user: { avatar: { url: string | null } | null }
	classNames?: string
}

export default function PreviewCardFooter(args: PreviewCardFooterArgs) {
	const { title, user, classNames } = args
	const { avatar } = user
	const thumbnail = avatar?.url ? getCDNUrl(avatar.url, { width: 300 }) : "/foil-avatar.jpg"

	return (
		<div
			className={twMerge(
				"absolute bottom-[-1px] z-20 flex w-full items-center justify-between gap-2 rounded-bl-lg rounded-br-lg bg-gradient-to-t from-[rgba(0,0,0,0.6)] p-2 opacity-0 transition-opacity duration-300 group-active/card:opacity-100 md:group-hover/card:opacity-100",
				classNames,
			)}>
			<div className="w-full truncate text-sm font-bold text-white">{title}</div>
			<Image alt={"avatar"} className="h-6 w-6 rounded-full" width={25} height={25} src={thumbnail} />
		</div>
	)
}
