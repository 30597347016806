import { useBreakpoint } from "@/hooks/useBreakpoints"
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { Drawer, DrawerContent, DrawerTitle } from "components/ui/drawer"
import { classNames } from "lib/classNames"
import { Fragment } from "react"

export interface PopupArgs {
	/** Determines if popup is showing or not */
	open?: boolean
	title?: string
	closeable?: boolean
	darkenUI?: boolean
	initialFocus?: any
	onClose?: () => void
	className?: string
	children: any
	// defaults to true,
	// controls whether or not input is allowed while the drawer is open
	modal?: boolean
}

export default function Popup(args: PopupArgs) {
	const {
		closeable = true,
		open,
		title,
		darkenUI,
		initialFocus = null,
		onClose,
		className,
		children,
		modal,
	} = args

	const isDesktop = useBreakpoint("md")

	function handleClose() {
		if (closeable) {
			onClose?.()
		}
	}

	if (!isDesktop) {
		return (
			<Drawer open={open} onClose={handleClose} modal={modal === undefined ? true : modal}>
				<style jsx global>{`
					body {
						pointer-events: auto !important;
					}
				`}</style>
				<DrawerContent className={classNames("rounded-b-none", "glass", "pb-4 md:px-8 md:pb-8")}>
					<DrawerTitle>{title}</DrawerTitle>
					{children}
				</DrawerContent>
			</Drawer>
		)
	}

	return (
		<Transition show={!!open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-[100]"
				initialFocus={initialFocus}
				open={!!open}
				onClose={handleClose}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm transition-all dark:bg-opacity-80" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-1 text-center sm:items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<DialogPanel
								className={"relative w-full transform overflow-hidden text-left transition-all sm:my-8"}>
								<div className={classNames("glass", "rounded-2xl p-6", "relative mx-auto", className)}>
									{closeable && (
										<div
											id="popup-close-btn"
											className="group absolute right-0 top-0 cursor-pointer p-6"
											onClick={handleClose}>
											<XMarkIcon className="w-5 group-hover:text-[#5901FB] dark:text-gray-300" />
										</div>
									)}
									{title && <div className="mb-6 text-sm font-bold dark:text-gray-300">{title}</div>}

									{children}
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
