import { classNames } from "lib/classNames"
import Image from "next/image"
import { LinkWrapperProps, Linky } from "./Linky"
import { getUserPermalinkPath } from "lib/utils.user"

interface ProfileUser {
	id: number
	username?: string | null
	displayName?: string | null
	picture?: string | null
	uuid: string
}
interface Args extends Pick<LinkWrapperProps, "link"> {
	user?: ProfileUser
	className?: string
}

export function UserProfileImage({ className, link, user }: Args) {
	const href = user && user.id ? getUserPermalinkPath(user) : undefined

	const src = user?.picture || "https://blocks.glass/foil-avatar.jpg"

	return (
		<Linky id="user-avatar-btn" href={href} link={link && user != null}>
			<Image
				unoptimized
				src={src}
				alt={user?.displayName ?? ""}
				width={40}
				height={40}
				style={{ objectFit: "cover" }}
				className={classNames("profile-picture aspect-h-1 rounded-full bg-black", className)}
			/>
		</Linky>
	)
}
