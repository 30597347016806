import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { Linky } from "components/Linky"
import { useBlocksUserStore } from "hooks/useStore"
import { Fragment, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { twMerge } from "tailwind-merge"
import Logo from "./Logo"
import MobileMenuFooter from "./MobileMenuFooter"
import MobileNavFooter from "./MobileNavFooter"
import MobileSubMenu from "./MobileSubMenu"
import { NavigationItem } from "./navigation"

interface MobileMenuProps {
	navItems: NavigationItem[]
	className?: string
}

export default function MobileMenu(props: MobileMenuProps) {
	const { navItems, className } = props
	const user = useBlocksUserStore((store) => store.dbUser)

	const [overlayPortal, setOverlayPortal] = useState<HTMLElement>()
	useEffect(() => {
		const overlayElement = document.getElementById("overlay")
		overlayElement && setOverlayPortal(overlayElement)
	}, [])

	return (
		<Menu id="mobile-menu" as="div" className={twMerge("", className)}>
			{({ open }) => {
				return (
					<>
						<style jsx global>
							{`
								body {
									overflow: ${open ? "hidden" : "auto"};
								}
								${open &&
								`
                #header {
                  background: none;
                }
                `}
							`}
						</style>

						<MenuButton as="button" className={"z-20 flex cursor-pointer items-center"}>
							<Logo />
							<ChevronDownIcon className="h-4 w-4 flex-shrink-0 sm:hidden" color="white" />
						</MenuButton>

						{overlayPortal &&
							createPortal(
								<>
									{open && (
										<div
											id="mobile-menu-background"
											className="absolute left-0 top-0 z-[3] h-[100vh] w-screen overflow-hidden border-2 border-none bg-[#594386] bg-opacity-80 outline-none backdrop-blur-md"
										/>
									)}
									{!open && user && <MobileNavFooter />}
								</>,
								overlayPortal,
							)}

						{overlayPortal &&
							createPortal(
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1">
									<MenuItems
										/* 
                      Use fixed positioning so we can pin the footer. 
                      Adjust top: 80px to match the header height if needed.
                    */
										className="pointer-events-auto fixed left-0 top-[80px] z-[4] flex h-[calc(100dvh-80px)] w-full flex-col justify-between overflow-hidden outline-none">
										<div className="flex-col gap-2 overflow-auto whitespace-nowrap px-2">
											{navItems.map((item) => {
												if (item.menu) {
													return (
														<MobileSubMenu
															name={item.name}
															key={`mobile-${item.name}-btn`}
															navItems={item.menu}
														/>
													)
												}
												return (
													<MenuItem
														href={item.href}
														target={item.openNewTab ? "_blank" : undefined}
														onClick={item.onClick}
														key={`mobile-${item.name}-link`}
														as={Linky}
														className={twMerge(
															"block rounded-md px-3 py-2 text-base font-medium text-white",
															"bg-white bg-opacity-0 transition-all duration-200 hover:bg-opacity-10 active:bg-opacity-10",
														)}>
														{item.name}
													</MenuItem>
												)
											})}
										</div>

										{/* Footer pinned to the bottom */}
										<div
											id="mobile-menu-footer"
											className="pointer-events-auto z-[4] flex w-full items-center px-4 py-2">
											<MobileMenuFooter />
										</div>
									</MenuItems>
								</Transition>,
								overlayPortal,
							)}
					</>
				)
			}}
		</Menu>
	)
}
