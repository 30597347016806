import { Hologram } from "graphql/client/gql/types"
import Popup from "components/Popup"
import { useState } from "react"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import { Violation } from "@prisma/client"
import { motion } from "framer-motion"
import PreviewCard from "components/PreviewCard/PreviewCard"
import toast from "react-hot-toast"
import { classNames } from "lib/classNames"
import { trpc } from "lib/trpc/trpc"
import { toastErrorStyle } from "components/toastStyles"
import { TRPCError } from "@trpc/server"
import { useBlocksUserStore } from "@/hooks/useStore"

export type FlagHologram = Pick<Hologram, "id" | "title" | "user"> | null

interface FlagHologramPopupArgs {
	hologram?: FlagHologram
	open: boolean
	setOpen: (value: FlagHologram) => void
}

export default function FlagHologramPopup(args: FlagHologramPopupArgs) {
	const [stage, setStage] = useState<number>(0)
	const [violation, setViolation] = useState<Violation>()

	const user = useBlocksUserStore((state) => state.dbUser)

	const [isError, setIsError] = useState<boolean>(false)

	const [comment, setComment] = useState<string>("")

	const validSubmission = (violation === "OTHER" && comment !== "") || violation !== "OTHER"

	const maxLength = 400

	const slideIn = {
		hidden: { opacity: 0, x: -100 },
		visible: { opacity: 1, x: 0 },
	}

	const slideOut = {
		hidden: { opacity: 0, x: 100 },
		visible: { opacity: 1, x: 0 },
	}

	if (!args.hologram) {
		return <></>
	}

	const { mutateAsync } = trpc.moderation.reportHologram.useMutation()

	async function handleSubmit() {
		if (!validSubmission) {
			toast("Please add additional details before submitting your report", toastErrorStyle)
			setIsError(true)
			return
		}

		if (args.hologram?.user.id === user?.id) {
			toast("You can't report your own hologram 😜", toastErrorStyle)
			return
		}

		if (!args.hologram?.id) {
			toast("Something went wrong", toastErrorStyle)
			throw new Error("hologram isn't available")
		}

		try {
			await mutateAsync({
				hologramId: args.hologram?.id,
				violation: violation!,
				message: comment,
			})
		} catch (e) {
			const error = e as unknown as TRPCError
			toast(error.message, toastErrorStyle)
		}

		args.setOpen(null)
	}

	function handleTextInput(event: any) {
		if (event.target.value.length <= maxLength) {
			setComment(event.target.value)
		}
	}

	return (
		<Popup
			title={`Report Hologram`}
			open={args.open}
			onClose={() => args.setOpen(null)}
			initialFocus={false}
			className="max-w-lg overflow-hidden">
			<div className="h-2/3 px-4 md:px-0">
				{stage === 0 && (
					<motion.div
						className="flex flex-col items-center text-center"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={slideIn}>
						<p className="block pb-4">Please select the option that best describes the problem.</p>
						<div className={"my-2 h-[2px] bg-white opacity-5"} />
						<div className="flex flex-col gap-2">
							{Object.values(Violation).map((violation, index) => (
								<div
									key={index}
									className="flex min-w-full flex-row justify-between rounded-lg bg-dark-purple-100/10 p-4 hover:cursor-pointer hover:bg-dark-purple-100/30 dark:bg-white/10 dark:hover:bg-white/30"
									onClick={() => {
										setStage(1)
										setViolation(violation)
									}}>
									<ViolationMessages violation={violation} />
									<ChevronRightIcon className="w-4" />
								</div>
							))}
						</div>
					</motion.div>
				)}
				{stage === 1 && (
					<motion.div
						className="flex flex-col justify-between gap-4"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={slideOut}>
						<div className="flex flex-col gap-2 text-left text-sm">
							<p className="whitespace-pre-line">
								Review your report before submitting. By submitting this report you confirm that it is
								truthful and made in good faith.
							</p>
							<p>
								{" "}
								Please follow our{" "}
								<a href="/code-of-conduct" target="_blank" className="text-[#C699FC] underline">
									Code of Conduct
								</a>{" "}
								and do not submit false or duplicate reports.
							</p>

							<div className={"my-2 h-[1px] bg-white opacity-5"} />
							<div className="flex flex-row items-center justify-center">
								<div
									className="max-w-[180px]"
									onClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
									}}>
									{/* @ts-ignore */}
									<PreviewCard hologram={args.hologram} showControls={false} />
								</div>
							</div>

							<div className={"my-2 h-[1px] bg-white opacity-5"} />
							<div className="flex flex-row gap-2 text-left text-lg">
								<strong>Report Category:</strong> <ViolationMessages violation={violation} />
							</div>
						</div>
						<textarea
							className={classNames(
								`${isError ? "border-red-500/50" : ""}`,
								"glass h-36 resize-none rounded-lg p-2",
							)}
							maxLength={maxLength}
							value={comment}
							onChange={(e) => {
								handleTextInput(e)

								setIsError(violation === "OTHER" && e.target.value === "")
							}}
						/>

						{isError && <p className="text-sm text-red-500">Please submit additional context.</p>}
						<p className="text-sm">
							{comment.length}/{maxLength} characters
						</p>

						<div className="flex flex-row gap-4">
							<button className="primaryButtonAlt w-full" onClick={() => setStage(0)}>
								Back
							</button>
							<button
								className="w-full rounded-lg bg-red-500 p-3 font-bold hover:bg-red-800"
								onClick={handleSubmit}>
								Report Hologram
							</button>
						</div>
					</motion.div>
				)}
			</div>
		</Popup>
	)
}

interface ViolationMessagesProps {
	violation: string | undefined
}

const ViolationMessages: React.FC<ViolationMessagesProps> = ({ violation }) => {
	switch (violation) {
		case Violation.DISCRIMINATION:
			return <p className="text-left">Discriminatory Content</p>
		case Violation.EXPLICIT:
			return <p className="text-left">Explicit or Sexual Content</p>
		case Violation.PRIVATE_INFO:
			return <p className="text-left">Content contains private or personal information</p>
		case Violation.VIOLENCE:
			return <p className="text-left">Content promotes or contains Violence</p>
		case Violation.OTHER:
			return <p className="text-left">Other</p>
		default:
			return <></>
	}
}
