import { EmbedHologram } from "@/lib/hologramProps"
import { useRef, forwardRef } from "react"
import { twMerge } from "tailwind-merge"

interface AnimationPreviewProps {
	hologram: EmbedHologram
	forceAspectRatio?: number
	autoplay?: boolean
	playing: boolean
}

const AnimationPreview = forwardRef<HTMLVideoElement, AnimationPreviewProps>(
	({ hologram, forceAspectRatio, autoplay }, ref) => {
		const videoRef = useRef<HTMLVideoElement>(null)

		const videoUrl = hologram.imageAssets
			.filter(({ type }) => type === "video/mp4")
			.sort((a, b) => a.width - b.width)[0]?.url

		const onLoadedVideoMetadata = () => {
			const { current } = videoRef
			if (current?.duration && !Number.isNaN(current?.duration)) {
				current.currentTime = (current.duration * 2) / 3 - 1
			}
		}

		if (!videoUrl) return null

		return (
			<video
				ref={(instance) => {
					// @ts-ignore
					videoRef.current = instance
					if (typeof ref === "function") {
						ref(instance)
					} else if (ref) {
						;(ref as React.MutableRefObject<HTMLVideoElement | null>).current = instance
					}
				}}
				className={twMerge(
					"absolute z-10 h-full w-full scale-[1.01] transition-opacity group-hover:opacity-100 group-active:opacity-100",
					forceAspectRatio &&
						"absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover",
				)}
				onLoadedMetadata={onLoadedVideoMetadata}
				playsInline
				loop
				muted
				preload={autoplay ? "auto" : "metadata"}
				autoPlay={autoplay}
				src={videoUrl}
			/>
		)
	},
)

AnimationPreview.displayName = "AnimationPreview"

export default AnimationPreview
