import { InformationCircleIcon } from "@heroicons/react/24/solid"
import DiscordIcon from "components/icons/DiscordIcon"
import LKGFolderIcon from "components/icons/LKGFolderIcon"
import PortraitIcon from "components/icons/PortraitIcon"
import SoftwareDownloadIcon from "components/icons/SoftwareDownloadIcon"
import { ForwardRefExoticComponent, SVGProps } from "react"

type SvgComponentType = (props: React.ComponentProps<"svg">) => JSX.Element

export interface NavigationItem {
	name: string
	href?: string
	onClick?: (e: any) => void
	openNewTab?: boolean
	topLevel?: boolean
	menu?: NavigationItem[]
	mobileOnly?: boolean
	adminOnly?: boolean
	authedOnly?: boolean
	hidden?: boolean
	Icon?: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref">> | SvgComponentType
	description?: string
}

export const NAV_LINKS: NavigationItem[] = [
	{ name: "Discover", href: "/discover" },
	{ name: "Library", href: "/library", authedOnly: true },
	{
		name: "Resources",
		href: "/learn",
		topLevel: true,
		menu: [
			{
				name: "Getting started",
				description: "Learn more about Blocks and its capabilities.",
				href: "https://docs.lookingglassfactory.com/software-tools/looking-glass-blocks",
				openNewTab: true,
				Icon: InformationCircleIcon,
			},
			{
				name: "3D software plugins",
				description: "Easily convert your 3D content to holograms.",
				href: "https://lookingglassfactory.com/software",
				openNewTab: true,
				Icon: SoftwareDownloadIcon,
			},
			{
				name: "Displays",
				description: "The world's first group-viewable 3D holographic displays. No headset required.",
				href: "https://lookingglassfactory.com",
				openNewTab: true,
				Icon: PortraitIcon,
			},
			{
				name: "Documentation",
				description: "Our docs aim to provide all the information you need to use your Looking Glass.",
				href: "https://docs.lookingglassfactory.com",
				openNewTab: true,
				Icon: LKGFolderIcon,
			},
			{
				name: "Discord",
				description: "Chat with other creators and get help from the Looking Glass team.",
				href: "https://discord.gg/lookingglassfactory",
				openNewTab: true,
				Icon: DiscordIcon,
			},
		],
	},
	{
		name: "Discord",
		href: "https://discord.gg/lookingglassfactory",
		hidden: true,
	},
	{
		name: "Help",
		topLevel: false,
		hidden: true,
		href: "",
		onClick: (e) => {
			e.preventDefault()
			if ("Beacon" in window && typeof window.Beacon === "function") {
				// @ts-ignore
				window.Beacon("open")
			}
		},
	},
	{
		name: "Admin",
		adminOnly: true,
		menu: [
			{
				name: "Moderate holograms",
				href: "/admin/moderation",
				openNewTab: true,
			},
			{
				name: "Review Latest",
				href: "/admin/moderation/latest",
			},
			{
				name: "Search holograms",
				href: "/admin/searchHolograms",
				openNewTab: true,
			},
			{
				name: "Analytics",
				href: "https://lookingglass.retool.com/apps/73183d26-8088-11ed-a9dc-db362e2dd911/Blocks/Blocks%20Analytics",
				openNewTab: true,
			},
			{
				name: "Manage users and flags",
				href: "/admin/manageUsers",
				openNewTab: true,
			},
			{
				name: "Manage devices",
				href: "/admin/displays",
				openNewTab: true,
			},
			{
				name: "Manage playlists",
				href: "https://lookingglass.retool.com/apps/5f10b97e-406a-11ed-a8db-633cddc2c387/Blocks/Blocks%20Playlists",
				openNewTab: true,
			},
			{
				name: "Discover Curation",
				href: "https://lookingglass.retool.com/apps/8ebaab26-7cab-11ed-9974-8ff30d79dfae/Blocks/Blocks%20-%20Discover%20Curation",
				openNewTab: true,
			},

			{
				name: "Batch Queues",
				href: "/admin/batch",
				openNewTab: true,
			},
			{
				name: "Job Queues",
				href: `${process.env.QUEUE_BASE_URL}/admin/queues`,
				openNewTab: true,
			},
			{
				name: "Recharge Webhooks",
				href: "/admin/recharge",
				openNewTab: true,
			},
		],
	},
]

export const USER_NAV_LINKS: NavigationItem[] = [
	{
		name: "Profile",
	},
	{
		name: "Library",
		href: "/library",
	},
	{
		name: "Settings",
		href: "/settings",
	},
	{
		name: "Log Out",
		href: "/api/auth/logout",
	},
]
