import axios from "axios"
import { Job } from "bull"
import { QueueInfo } from "../globals"
import createQueue from "../../queues/createQueue"

type Args = {
	url: string
}

/** Fetch all the possible quilt urls so that they are pre-generated */
export const WarmURL: QueueInfo<Args> = {
	concurrency: 5,
	queue: createQueue<Args>("WarmURL", {
		redisHost: "default",
		defaultJobOptions: {
			priority: 60,
			removeOnComplete: {
				count: 1000,
				age: 60 * 60, // 1 hour
			},
		},
	}),
	process: async (job, done) => {
		try {
			await fetchHead({ url: job.data.url, job, done })

			done(null, { success: true })
		} catch (e) {
			done(e as Error)
		}
	},
}

interface FetchHeadArgs {
	url: string
	job: Job
	done: (err: Error) => void
}

async function fetchHead({ url, job, done }: FetchHeadArgs): Promise<boolean> {
	if (url) {
		const res = await axios.head(url)

		if (res.status !== 200) {
			done(new Error(`Warm failed ${url} ${res.status} ${res.statusText}`))
			return false
		}

		await job.log(
			`Warmed ${res.headers["content-type"]} length:${res.headers["content-length"]} x-cache:${res.headers["x-cache"]}\n${url}`,
		)
		return true
	}
	return false
}
