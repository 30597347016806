import { NexusHologram } from "@/graphql/server/types"
import { cloneDeep } from "@apollo/client/utilities"
import { Prisma, PrismaClient, Hologram as PrismaHologram, Privacy } from "@prisma/client"
import { validate } from "uuid"
import { getCDNUrl } from "../../lib/cdn"
import { EmbedHologram, EmbedHologramInclusion, EmbedImageAsset } from "../../lib/hologramProps"
import { getQuiltConfigFromFilename, stripQuiltConfig } from "../../lib/utils"
import {
	getHologramDefaults,
	getImageAngleFromQuilt,
	getThumbnail,
	getTotalAngles,
} from "../../lib/utils.hologram"
import { getUserPicture } from "../../lib/utils.user"
import { getQueue } from "../../queues/getQueue"
import { DeleteFromS3 } from "../../queues/jobs/deleteFromS3"
import { UpdateFileNameOnS3 } from "../../queues/jobs/updateFileName"
import { WarmQuiltCache } from "../../queues/jobs/warmQuilts"

export type ImageAssetCreateData = {
	url: string
	width: number
	height: number
	fileSize: number
	type: string
}

// Quilt Image Constants
export const HOLOGRAM_QUILT_IMAGE_FORMATS = ["png", "jpg", "jpeg", "webp", "bmp"]
export const HOLOGRAM_QUILT_IMAGE_MIMETYPES = HOLOGRAM_QUILT_IMAGE_FORMATS.map((f) => `image/${f}`)
export const HOLOGRAM_DEFAULT_ASPECT_RATIO = 0.75
export const HOLOGRAM_DEFAULT_QUILT_ROWS = 9
export const HOLOGRAM_DEFAULT_QUILT_COLS = 5
export const HOLOGRAM_DEFAULT_TILE_COUNT = 45
// 3D Image Constants
export const HOLOGRAM_3D_IMAGE_FORMATS = ["png", "jpg", "jpeg", "webp", "bmp", "heic"]
export const HOLOGRAM_3D_IMAGE_MIMETYPES = HOLOGRAM_3D_IMAGE_FORMATS.map((f) => `image/${f}`)

export const HOLOGRAM_DEFAULT_RGBD_FOCUS = 0.0
export const HOLOGRAM_MIN_FOCUS = -1
export const HOLOGRAM_MAX_FOCUS = 1
export const HOLOGRAM_MIN_RGBD_FOCUS = -1.0
export const HOLOGRAM_MAX_RGBD_FOCUS = 1.0

export const HOLOGRAM_THRESHOLD_RGBD_DEPTHINESS = 0.11 // used to set when a hologram is flat

export const HOLOGRAM_DEFAULT_RGBD_DEPTHINESS = 0.15
export const HOLOGRAM_MIN_RGBD_DEPTHINESS = 0.001 // 0 but a bit higher
export const HOLOGRAM_MAX_RGBD_DEPTHINESS = 0.3 // max depth value

export const HOLOGRAM_DEFAULT_RGBD_ZOOM = 0.6
export const HOLOGRAM_DEFAULT_RGBD_STRETCH = 1
export const HOLOGRAM_DEFAULT_QUILT_ZOOM = 0.5
export const HOLOGRAM_MIN_QUILT_ZOOM = 0.5
export const HOLOGRAM_MIN_ZOOM = 0.6
export const HOLOGRAM_MAX_ZOOM = 2
export const HOLOGRAM_DEFAULT_CROP_POS_X = 0.0
export const HOLOGRAM_DEFAULT_CROP_POS_Y = 0.0
export const HOLOGRAM_DEFAULT_PRODUCT_ASPECT = 0.5625

type CreateData = Omit<Prisma.HologramUncheckedCreateInput, "id" | "title"> & {
	title?: string
	sourceImage: ImageAssetCreateData
}

type FindHologram = {
	/** Helper for figuring which type it is */
	idOrUuid?: string
	requestUserId?: number
	/** If true, will return a hologram even if it's private */
	allowPrivate?: boolean
	productAspect?: number
}

export type FindHologramQueryArgs = {
	id?: number
	uuid?: string
	privacy?: Privacy
	isPublished?: boolean
}

export function exclude<T, Key extends keyof T>(hologram: T, keys: Key[]): T {
	for (let key of keys) {
		delete hologram[key]
	}
	return hologram
}

export function excludeArray<T, Key extends keyof T>(holograms: T[], keys: Key[]): T[] {
	return holograms.map((hologram) => exclude(hologram, keys))
}

export const PrivateHologramFields: (keyof PrismaHologram)[] = [
	"uuid",
	"canUsersDownload",
	"canAddToPlaylist",
	"totalViews7d",
	"totalViews30d",
	"totalViews",
	"isShadowBan",
]

export function Holograms(prisma: PrismaClient) {
	return {
		async update(id: number, data: Prisma.HologramUpdateInput) {
			const hologram = await prisma.hologram.update({
				data,
				where: {
					id: id,
				},
				include: { user: true, imageAssets: true },
			})

			if (data.title) {
				await UpdateFileNameOnS3.queue.add({ hologramId: id })
			}

			if (hologram.privacy === "PUBLIC") {
				getQueue("createAnimatedPreviews").add({ hologramId: hologram.id })
			}
			return hologram
		},

		async findFirst(args: FindHologram) {
			let { idOrUuid, requestUserId, allowPrivate, productAspect } = args

			if (!idOrUuid) return null

			const isUuid = validate(idOrUuid)
			const where: Prisma.HologramWhereInput = {}

			if (isUuid) {
				where.uuid = idOrUuid
			} else {
				where.id = Number.parseInt(idOrUuid)
			}

			const requestUser = !!requestUserId
				? await prisma.user.findFirst({ where: { id: requestUserId } })
				: undefined

			const isAdmin = !!(requestUser?.role === "ADMIN")

			const hologram = await prisma.hologram.findFirst({
				where,
				include: EmbedHologramInclusion,
			})

			if (!hologram) return null

			if (!hologram.user) return null
			// @ts-ignore
			hologram.user.picture = getUserPicture(hologram.user)

			if (!allowPrivate && !isAdmin) {
				const { privacy, isPublished } = hologram
				if (!isPublished && requestUserId !== hologram.userId) return null
				switch (privacy) {
					case "PUBLIC":
						if (isUuid && requestUserId !== hologram.userId) return null
						break
					case "UNLISTED":
						if (!isUuid && requestUserId !== hologram.user.id) return null
						break
					case "ONLY_ME":
						if (requestUserId !== hologram.userId) return null
						break
				}
			}

			// Check if `productAspect` is specified, and retrieve HologramProductSetting if available
			if (productAspect) {
				const productSettings = await prisma.hologramProductSettings.findMany({
					where: { hologramId: hologram.id },
				})

				if (productSettings) {
					const productSetting = productSettings.find((setting) => setting.productAspect === productAspect)
					if (productSetting) {
						hologram.crop_pos_x = productSetting.crop_pos_x ?? hologram.crop_pos_x
						hologram.crop_pos_y = productSetting.crop_pos_y ?? hologram.crop_pos_y
						hologram.rgbdZoom = productSetting.zoom ?? hologram.rgbdZoom
					}
				}
			}

			const result = getHologramDefaults(hologram)
			return result
		},

		async create(args: CreateData) {
			args.type ||= "QUILT"

			if (args.type == "QUILT") {
				const quiltSettingsMatch = getQuiltConfigFromFilename(args.sourceImage.url)
				if (quiltSettingsMatch) {
					args = {
						...quiltSettingsMatch,
						...args, // input args should have priority
					}
				}
			}

			let title = args.title ?? "Untitled"

			// If no title set, use the filename
			if (!args.title) {
				// Get the filename
				title = args.sourceImage.url.split("/").pop() ?? ""

				// replace underscores with spaces
				title = title.replace(/_/g, " ")

				// Strip file extensions
				title = title.replace(/\.[a-z]{3,4}$/, "")

				// Strip quilt settings
				title = stripQuiltConfig(title)

				title = title.trim()
			}

			// Remove the image asset args... is there a simpler way to do this?
			let hologramData = cloneDeep(args)

			// Apply a shadow ban to the created hologram if the user is shadow banned
			const { isShadowBan } = await prisma.user.findFirstOrThrow({ where: { id: args.userId } })
			hologramData.isShadowBan = isShadowBan

			// @ts-ignore
			delete hologramData.sourceImage
			// @ts-ignore
			delete hologramData.imageUrl
			// @ts-ignore
			delete hologramData.width
			// @ts-ignore
			delete hologramData.height
			// @ts-ignore
			delete hologramData.fileSize

			const hologram = await prisma.hologram.create({
				data: {
					...hologramData,
					title: title.substring(0, 32),
				},
			})

			await prisma.imageAsset.create({
				data: dataForImageAssetCreateOrUpdate(hologram.id, args.sourceImage),
			})

			if (args.type == "QUILT") {
				await WarmQuiltCache.queue.add({ id: hologram.id })
			}

			if (hologramData.privacy === "PUBLIC") {
				getQueue("createAnimatedPreviews").add({ hologramId: hologram.id })
			}
			getQueue("hubspotContactSync").add({ id: args.userId, context: "createHologram" })

			return await prisma.hologram.findFirstOrThrow({
				where: { id: hologram.id },
				include: {
					imageAssets: true,
					user: { include: { avatar: true } },
					Settings: true,
				},
			})
		},

		/** Delete the hologram. All associated S3 files will be queued for deletion */
		async delete(hologramId: number) {
			// Fetch all the assets to be deleted
			const files = await prisma.imageAsset.findMany({ where: { hologramId } })

			// ensures that syncItems are deleted before the hologram's playlistItems are deleted.
			const syncItems = await prisma.syncItem.deleteMany({ where: { playlistItem: { hologramId } } })

			// Delete the hologram
			const hologram = await prisma.hologram.delete({ where: { id: hologramId } })

			// If delete doesnt fail, then queue files to be deleted
			if (files.length > 0) {
				DeleteFromS3.queue.add(
					files.map((f) => f.url),
					{
						// delay job for 7 days is a precaution to prevent accidental deletion
						delay: 1000 * 60 * 60 * 24 * 7,
					},
				)
			}

			return hologram
		},

		async replaceSourceImage(hologramId: number, createInput: ImageAssetCreateData) {
			const quiltSettingsMatch = getQuiltConfigFromFilename(createInput.url)
			// If user uploaded a file with new quilt settings, update the hologram with those settings
			if (quiltSettingsMatch) {
				await this.update(hologramId, {
					quiltCols: quiltSettingsMatch.quiltCols,
					quiltRows: quiltSettingsMatch.quiltRows,
					quiltTileCount: quiltSettingsMatch.quiltTileCount,
					aspectRatio: quiltSettingsMatch.aspectRatio,
				})
			}

			// Creating a new image asset assumes it will replace the previous quilt (see Holograms.sourceImages)
			await prisma.imageAsset.create({
				data: dataForImageAssetCreateOrUpdate(hologramId, createInput),
			})

			await WarmQuiltCache.queue.add({ id: hologramId })

			return await prisma.hologram.findFirstOrThrow({
				where: { id: hologramId },
				include: {
					imageAssets: true,
					user: { include: { avatar: true } },
					Settings: true,
				},
			})
		},

		async getQuiltAngleImages(hologram: EmbedHologram & { id?: number }, width: number = 1000) {
			if (!hologram.id) throw new Error("Hologram must have an ID to fetch quilt angle images")

			const sourceImages = await this.sourceImages(hologram.id)

			if (sourceImages == null || sourceImages.length == 0) return []

			return [...Array(getTotalAngles(hologram) || 0)].map((_, i) =>
				getImageAngleFromQuilt(hologram, sourceImages?.[0], i, width, {
					fit: "scale",
					fm: "png",
					auto: null,
				}),
			)
		},

		async thumbnail(hologram: EmbedHologram & { id: number }, width: number = 1200) {
			if (hologram.id) {
				const sources = await this.sourceImages(hologram.id)

				if (sources != null && sources.length > 0) {
					return getThumbnail(hologram, width)
				}
			}

			return null
		},

		async thumbnails(hologram: NexusHologram, width: number = 1200) {
			if (hologram.id == undefined) return null
			const thumbSizes = [250, 500, 750, 1000, 1200]
			const source = findSourceImage(hologram)
			if (!source) return []
			return thumbSizes.map((size) => getThumbnail(hologram, size))
		},

		async rgbdAssets(id: number, useCDN: boolean = true) {
			let images = await prisma.hologram
				.findUnique({
					where: { id },
				})
				.imageAssets(HologramRgbdImageArgs)

			// Convert S3 URLs to Cloudfront URLs
			return images?.map((i) => {
				if (useCDN) {
					i.url = getCDNUrl(i.url).toLowerCase()
				}
				return i
			})
		},
		/**RGBD Holograms can have generated quilts */
		async generatedQuilts(id: number, useCDN: boolean = true) {
			let images = await prisma.hologram
				.findUnique({
					where: { id },
				})
				.imageAssets({ where: { kind: "GENERATED_QUILT" }, orderBy: { id: "desc" } })

			// Convert S3 URLs to Cloudfront URLs
			return images?.map((i) => {
				if (useCDN) {
					i.url = getCDNUrl(i.url).toLowerCase()
				}
				return i
			})
		},

		/** A Hologram can have many source assets. The most recently uploaded is considered the latest */
		async sourceImages(id: number, useCDN: boolean = true) {
			let images = await prisma.hologram
				.findUnique({
					where: { id },
				})
				.imageAssets(HologramSourceQuiltImageArgs)

			// Convert S3 URLs to Cloudfront URLs
			return images?.map((i) => {
				if (useCDN) {
					i.url = getCDNUrl(i.url)
				}
				return i
			})
		},

		async getShadowBanStatus(id: number) {
			const { isShadowBan } = await prisma.hologram.findFirstOrThrow({
				where: { id },
				select: { isShadowBan: true },
			})
			return isShadowBan
		},

		async setShadowBanStatus(id: number, isShadowBan: boolean) {
			const { isShadowBan: result } = await prisma.hologram.update({ data: { isShadowBan }, where: { id } })
			return result
		},

		async getCanUsersDownloadAssets(id: number) {
			const { canUsersDownload } = await prisma.hologram.findFirstOrThrow({
				where: { id },
				select: { canUsersDownload: true },
			})
			return canUsersDownload
		},

		async setCanUsersDownloadAssets(id: number, canUsersDownload: boolean) {
			const { canUsersDownload: result } = await prisma.hologram.update({
				data: { canUsersDownload },
				where: { id },
			})
			return result
		},
	}
}

export const HologramRgbdImageArgs: Prisma.ImageAssetFindManyArgs = {
	where: { kind: { in: ["RGBD", "DEPTH"] } },
	orderBy: { id: "desc" },
	take: 2,
}

export const findSourceImage = <T extends EmbedImageAsset>(hologram: { imageAssets: T[] }) => {
	return hologram.imageAssets
		?.sort((a, b) => a.id - b.id)
		.reverse()
		.find(
			(i) =>
				i.kind === "SOURCE" || (i.kind === "NONE" && !i.type?.includes("gif") && !i.type?.includes("video")),
		)
}

export const findAllSourceImages = <T extends EmbedImageAsset>(hologram: { imageAssets: T[] }) => {
	return hologram.imageAssets
		.sort((a, b) => a.id - b.id)
		.reverse()
		.filter(
			(i) =>
				i.kind === "SOURCE" || (i.kind === "NONE" && !i.type?.includes("gif") && !i.type?.includes("video")),
		)
}

export const HologramSourceQuiltImageArgs: Prisma.ImageAssetFindManyArgs = {
	// Scope it to PNGs only (since we are storing GIFs and Videos now)
	where: {
		OR: [{ type: { in: HOLOGRAM_QUILT_IMAGE_MIMETYPES }, kind: "NONE" }, { kind: "SOURCE" }],
	},
	orderBy: { id: "desc" },
	take: 1,
}

// get the asset to sync to the Go
export const HologramSyncImageArgs: Prisma.ImageAssetFindManyArgs = {
	where: {
		OR: [
			{ kind: "GENERATED_QUILT" },
			{ type: { in: HOLOGRAM_QUILT_IMAGE_MIMETYPES }, kind: "NONE" },
			{ kind: "SOURCE" },
		],
	},
	orderBy: { id: "desc" },
	take: 1,
}

function dataForImageAssetCreateOrUpdate(
	hologramId: number,
	data: ImageAssetCreateData,
): Prisma.ImageAssetCreateArgs["data"] {
	// Get the correct mime type
	const mimeTypeMatch = data.url.match(`(${HOLOGRAM_QUILT_IMAGE_FORMATS.join("|")})`)
	let mimeType = "image/png"
	if (mimeTypeMatch) {
		mimeType = `image/${mimeTypeMatch[1]}`
	}

	return {
		url: normalizeS3Url(data.url),
		width: data.width,
		height: data.height,
		type: data.type ?? mimeType,
		fileSize: data.fileSize,
		hologramId: hologramId,
		kind: "SOURCE",
	}
}

export function findByIdWhere(data: FindHologramQueryArgs, isLoggedIn: boolean): FindHologramQueryArgs {
	let where: FindHologramQueryArgs = {}

	if (data.id && !data.id.toString().match(/^[0-9]+$/)) {
		where = {
			uuid: data.id.toString(),
		}
	} else if (data.uuid) {
		where = {
			uuid: data.uuid,
		}
	} else {
		where = {
			id: parseInt(data.id?.toString() ?? "0"),
		}
	}

	// If not logged in, then dont allow users to query private holograms
	if (!isLoggedIn) {
		where.isPublished = true

		if (where.uuid) {
			where.privacy = "UNLISTED"
		} else {
			where.privacy = "PUBLIC"
		}
	}

	return where
}

/** Convert S3 URLs to a standard structure and remove any query strings from the URL */
export function normalizeS3Url(url: string): string {
	// move the bucket name to folder path
	const bucketMatch = url.match(/https:\/\/([a-zA-Z0-9-]+).s3.amazonaws.com\/(.*)/)
	if (bucketMatch) {
		url = `https://s3.amazonaws.com/${bucketMatch[1]}/${bucketMatch[2]}`
	}

	// Strip query string out if it exists
	const queryMatch = url.match(/(.+?)\?(.*)/)
	if (queryMatch) {
		return queryMatch[1]
	}

	return url
}
