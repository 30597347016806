import { useBlocksUserStore } from "@/hooks/useStore"
import { gtmUserEvent } from "lib/analytics"
import { useEffect } from "react"

export default function Analytics() {
	const user = useBlocksUserStore((state) => state.dbUser)
	const isLoggedIn = useBlocksUserStore((state) => state.current?.isLoggedIn)

	/** Send Analytics events to GTM and Hubspot */
	useEffect(() => {
		if (isLoggedIn && user) {
			if (process.env.DISABLE_LOGINS == "1") {
				window.location.href = "/api/auth/logout"
				return
			}

			gtmUserEvent(user, "user-login-success")
		}
	}, [isLoggedIn, user?.id])

	return <></>
}
