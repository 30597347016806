import { useHover } from "@uidotdev/usehooks"
import HologramThumbnail from "components/HologramThumbnail"
import { motion } from "framer-motion"
import { useCastStore } from "hooks/useCastStore"
import { classNames } from "lib/classNames"
import { EmbedHologram } from "lib/hologramProps"
import { getClosestImage } from "lib/utils.hologram"
import Link from "next/link"
import { useRef, useState } from "react"
import { MathUtils } from "three"
import AnimationPreview from "./AnimationPreview"
import Overlay from "./Overlay"
import styles from "./PreviewCard.module.css"

export interface PreviewCardArgs {
	hologram: EmbedHologram
	animateOnHover?: boolean
	autoplay?: boolean

	// Select Props
	selectable?: boolean
	selected?: boolean
	onSelected?: any
	playlistItemID?: number

	// Show UI controls
	showControls?: boolean

	forceAspectRatio?: number
	className?: string
}

const getVideoAssets = (hologram: EmbedHologram) => {
	return hologram.imageAssets?.filter(({ type }) => type === "video/mp4")
}

const MotionLink = motion(Link)

const PreviewCard = (args: PreviewCardArgs) => {
	const {
		hologram,
		animateOnHover = false,
		autoplay = false,
		selectable,
		onSelected,
		selected,
		showControls = true,
		playlistItemID = undefined,
		forceAspectRatio,
		className,
	} = args
	const { user, id } = hologram
	let { aspectRatio } = hologram
	const { username } = user

	const [isImageLoading, setIsImageLoading] = useState(true)
	const [ref, isHovered] = useHover()

	const videoRef = useRef<HTMLVideoElement>(null)

	const castHologram = useCastStore((store) => store.hologram)
	const castPlaylistItemId = useCastStore((store) => store.playlistItemId)

	const hologramUrl = hologram.privacy === "PUBLIC" ? `/${username}/${id}` : `/${username}/${hologram.uuid}`

	const videoAssets = getVideoAssets(hologram)
	const video = videoAssets && getClosestImage(videoAssets, 300)
	const videoUrl = video?.url ?? ""

	const showVideo = videoUrl && (autoplay || animateOnHover)

	if (aspectRatio === 0 && video && !forceAspectRatio) {
		aspectRatio = video.width! / video.height!
	}

	aspectRatio = forceAspectRatio ?? aspectRatio

	const Play = async () => {
		if (!videoRef.current || !animateOnHover || autoplay || !videoUrl) return
		const { current } = videoRef
		if (current.readyState <= 1) {
			videoRef.current.preload = "auto"
			videoRef.current.load()
			return
		}

		videoRef.current.play()
	}

	const onImageLoaded = () => {
		const delay = MathUtils.randInt(0, 100)
		setTimeout(() => setIsImageLoading(false), delay)
	}

	const pause = () => {
		const { current } = videoRef
		if (current) {
			current.pause()
		}
	}

	return (
		<MotionLink
			href={encodeURI(hologramUrl)}
			ref={ref}
			onTouchStart={Play}
			onTouchEnd={pause}
			onMouseEnter={Play}
			onMouseLeave={pause}
			className={classNames(
				"hologram-preview-card group/card relative z-0",
				className,
				`${args.selected ? `${styles.gradientBorder}` : ""}`,
				// if we're casting a hologram, display the cast style
				`${hologram.id === castHologram?.id && castPlaylistItemId === undefined ? styles.pulse : ""}`,
				// if we're casting a playlistItem, display the cast style
				`${playlistItemID === castPlaylistItemId && playlistItemID !== undefined ? styles.pulse : ""}`,
			)}
			id={`hologram-${id}`}
			style={{
				aspectRatio: `${aspectRatio || 0.75}`,
			}}
			whileHover={{ scale: isImageLoading ? 1 : 1.03 }}>
			<HologramThumbnail onImageLoaded={onImageLoaded} hologram={hologram} width={600} />
			{showVideo && (
				<AnimationPreview
					hologram={hologram}
					autoplay={autoplay}
					playing={isHovered}
					forceAspectRatio={forceAspectRatio}
					ref={videoRef}
				/>
			)}
			{showControls && (
				<Overlay
					hologram={hologram}
					playlistItemID={playlistItemID}
					selected={Boolean(selected)}
					selectable={Boolean(selectable)}
					onSelected={onSelected}
				/>
			)}
		</MotionLink>
	)
}

export default PreviewCard
