import { findSourceImage, HOLOGRAM_DEFAULT_QUILT_COLS } from "@/prisma/models"
import useHologramControls from "hooks/useHologramControls"
import useImagePreloader from "hooks/useImagePreloader"
import useTrackLoadTime from "hooks/useTrackLoadTime"
import { classNames } from "lib/classNames"
import { timeout } from "lib/utils"
import { getThumbnail } from "lib/utils.hologram"
import Image from "next/image"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { RendererProps } from "../HologramEmbed"
import { HTMLQuilt } from "./HTMLQuilt"
import styles from "./Renderer.module.css"
import { getCDNUrl } from "@/lib/cdn"

function normalizeRange(value, inMin, inMax, outMin, outMax) {
	return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

export default function HTMLHologramRenderer(props: RendererProps) {
	const { hologram, isEditMode, overrideAspect } = props
	const [showThumbnail, setShowThumbnail] = useState(false)
	const [showQuilt, setShowQuilt] = useState(false)
	const [refresh, setRefresh] = useState(false)

	const aspectRatio = hologram?.aspectRatio ?? 0.75

	const containerAspect = overrideAspect ?? aspectRatio
	// this sets the perspective value of the css transform
	// makes sure the perspective is consistent across varying div sizes
	const perspectiveSize = useMemo(() => {
		if (props.height) {
			return (props.height * 2).toFixed(0)
		}
		return "2400"
	}, [props.height])
	const hologramRef = useRef<HTMLDivElement>(null)
	const perspectiveContainerRef = useRef<HTMLDivElement>(null)
	const quiltSetView = useRef((viewValue) => {})

	const quiltImageWidth = useRef<number>(0)

	// grab the quilt image URL
	const sourceImage = hologram ? findSourceImage(hologram) : null

	const quiltImageUrl = useMemo(() => {
		const maxDimension = Math.max(
			hologramRef.current?.offsetWidth ?? 1024,
			hologramRef.current?.offsetHeight ?? 1024,
		)

		const columns = hologram?.quiltCols ?? HOLOGRAM_DEFAULT_QUILT_COLS
		return (
			sourceImage &&
			getCDNUrl(sourceImage.url, { width: Math.min(7000, Math.max(maxDimension * columns, 1024)) })
		)
	}, [sourceImage])

	quiltImageWidth.current = parseInt(quiltImageUrl?.match(/width=(\d+)/)?.[1] ?? "0")

	function setContainerView(viewValue: number) {
		if (!props.frameTilt) return

		const rotateY = ((viewValue - 0.5) * props.viewcone).toFixed(2)
		perspectiveContainerRef.current?.setAttribute(
			"style",
			`transform: perspective(${perspectiveSize}px) rotateY(${rotateY}deg);` +
				`transform-style: preserve-3d;`,
		)
	}

	function setQuiltView(viewValue: number) {
		props?.onViewAngleChange?.((viewValue - 0.5) * 2)
		quiltSetView.current(viewValue)
	}

	const thumbnail = hologram && getThumbnail(hologram)

	const blurredPreviewUrl = thumbnail?.url + "&blur=16"

	// quilt preloading
	// use the image preloader hook to detect when the quilt is finished loading
	const { imagesPreloaded: thumbnailPreloaded } = useImagePreloader([blurredPreviewUrl])
	const { imagesPreloaded: quiltPreloaded, totalCachedImages } = useImagePreloader([quiltImageUrl])

	// Track when quilt finishes loading (only if it's not cached)
	useTrackLoadTime(quiltPreloaded && totalCachedImages == 0, quiltImageWidth.current)

	/**
	 * Manually trigger the display of the thumbnail & quilt
	 * so that we have more control over the animation
	 */
	useEffect(() => {
		if (quiltPreloaded && props.width > 0) {
			props.onLoad?.()
			setShowThumbnail(true)
			setShowQuilt(true)
		}
		if (thumbnailPreloaded) {
			setShowThumbnail(true)
		}

		if (!thumbnailPreloaded) {
			setShowThumbnail(false)
		}
	}, [thumbnailPreloaded, quiltPreloaded, props.width])

	/**
	 * onViewAngleChange is passed in to useHologramControls in this case, we
	 * want to call that function in addition to setting the view when we change view angles
	 */
	const onViewAngleChange = useCallback(
		(normalizedViewRotY: number, containerRotY: number) => {
			// Stop the looping animation if it's running once the user moves their mouse
			// if (normalizedViewRotY != 0 && containerRotY != 0 && props.animate) {
			// 	// animateApi.stop()
			// }

			setContainerView(containerRotY * 0.5 + 0.5)
			setQuiltView(normalizedViewRotY * 0.5 + 0.5)
		},
		[props.onViewAngleChange, perspectiveSize, props.animate],
	)

	useEffect(() => {
		if (refresh) setRefresh(false)
	}, [refresh])

	// set up the controls
	useHologramControls({
		onViewAngleChange: onViewAngleChange,
		isQuiltLoaded: quiltPreloaded,
		rendererProps: props,
		refresh,
		circularRotation: false,
	})

	useEffect(() => {
		if (!hologramRef.current) return

		// transform controls
		const hologramZoom = props.hologram?.rgbdZoom ?? 1
		const zoom = normalizeRange(hologramZoom, 0.5, 2, 1, 4)
		// Calculate transform values based on crop position
		const cropPosX = (props.hologram?.crop_pos_x ?? 0) * -100 * zoom
		const cropPosY = (props.hologram?.crop_pos_y ?? 0) * 100 * zoom

		// Update hologram's transform style with translate3d for 3D support
		hologramRef.current.style.transform = `translate3d(${cropPosX}%, ${cropPosY}%, 0) scale(${zoom})`

		hologramRef.current.style.transformStyle = "preserve-3d"
	}, [props.hologram?.crop_pos_x, props.hologram?.crop_pos_y, props.hologram?.rgbdZoom])

	if (!quiltImageUrl) {
		return <></>
	}

	return (
		<div id="hologram-element" className={"h-full w-full"} ref={perspectiveContainerRef}>
			<div className={classNames("flex h-full w-full content-center justify-center")}>
				<div
					style={{
						aspectRatio: containerAspect,
						maxHeight: "100%",
						width: "100%",
						maxWidth: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						overflow: "hidden",
					}}
					className={`self-center rounded-xl ${isEditMode ? "border-2 border-solid border-white/40 bg-black/50 backdrop-blur-3xl" : ""}`}>
					<div
						ref={hologramRef}
						style={{
							aspectRatio: aspectRatio ?? 1,
							width: aspectRatio >= (containerAspect ?? 1) ? "100%" : "auto",
							height: aspectRatio >= (containerAspect ?? 1) ? "auto" : "100%",
							maxWidth: "100%",
							maxHeight: "100%",
							objectFit: "contain",
						}}>
						<div
							className={classNames(
								props.dropshadow ? "shadow-xl" : "",
								"pointer-events-none relative h-full w-full overflow-hidden",
							)}>
							<Byline {...props} visible={showQuilt} quiltLoaded={showQuilt} />

							<div
								className={classNames(
									"absolute z-20 h-full w-full scale-[1.015] transition-opacity duration-[700ms] ease-in-out",
									showQuilt ? "opacity-100" : "opacity-0",
									"overflow-visible",
								)}>
								<HTMLQuilt rendererProps={props} quiltImageUrl={quiltImageUrl} setView={quiltSetView} />
							</div>
						</div>
					</div>
					<div
						style={{
							position: "absolute",
							aspectRatio: containerAspect,
							width: aspectRatio >= (containerAspect ?? 1) ? "100%" : "auto",
							height: aspectRatio >= (containerAspect ?? 1) ? "auto" : "100%",
							maxWidth: "100%",
							maxHeight: "100%",
							objectFit: "contain",
						}}>
						{hologram && (
							<BlurredPreviewImage
								visible={showThumbnail}
								quiltLoaded={showQuilt}
								className={"z-[25] duration-500"}
								src={blurredPreviewUrl}
							/>
						)}

						<BorderFrameAnimation
							visible={!quiltPreloaded}
							quiltLoaded={showQuilt}
							className={"z-[22] duration-500"}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

interface LayerProps {
	visible: boolean
	quiltLoaded: boolean
	src?: string
	className?: string
	ref?: any
	children?: React.ReactNode
}

type BylineProps = LayerProps & RendererProps

export function Byline(props: BylineProps) {
	const visibility = useVisibility(props, false)

	if (!props.showCreator && props.showTitle) {
		return <></>
	}

	if (!props.hologram?.user) {
		throw new Error("Hologram must have a user")
	}

	return (
		<div
			className={classNames(
				"absolute z-30 flex h-full w-full place-items-end transition-opacity ease-in-out",
				props.className,
				visibility,
			)}>
			<div className="p-4 text-xs font-bold md:p-6 md:text-base">
				{props.showTitle && props.hologram?.title}
			</div>
			<div className="p-4 text-xs font-bold md:p-6 md:text-base">
				{props.showCreator && `by ${props.hologram.user.displayName}`}
			</div>
		</div>
	)
}

export function BlurredPreviewImage(props: LayerProps) {
	const visibility = useVisibility(props)

	if (!props.src) {
		return <></>
	}

	return (
		<div
			className={classNames(
				"absolute left-0 top-0 h-full w-full scale-[0.999] p-1 transition-opacity",
				props.className,
				visibility,
			)}>
			<div className="relative h-full w-full p-2">
				<Image
					src={props.src}
					fill={true}
					style={{ objectFit: "cover" }}
					className="rounded-xl"
					quality={100}
					alt=""
					sizes="{max-width: 1200px} 100vw"
					priority={true}
				/>
			</div>
		</div>
	)
}

/** Shout out to Joe Bell
 * https://twitter.com/joebell_/status/1557355193473585153
 */
export const BorderFrameAnimation = (props: LayerProps) => {
	const visibility = useVisibility(props)

	return (
		<div
			ref={props.ref}
			className={classNames(
				props.className,
				visibility,
				"absolute left-0 top-0 h-full w-full scale-[0.999] rounded-xl transition-opacity",
			)}>
			<div className={classNames(styles.button, styles.isBusy, "h-full w-full")}>
				<div className={classNames(styles.content, "h-full w-full rounded-xl")}>&nbsp;</div>
				<div className={classNames(styles.disco, "rounded-xl")} />
			</div>
		</div>
	)
}

/**
 * Helper hook to more easily control when a layer fades out / gets removed.
 * TODO: should we be using a tween library?
 */
function useVisibility(props: LayerProps, delayHide: boolean = true) {
	const [className, setClassName] = useState<string>("opacity-0")

	async function delay(className: string, delay: number) {
		await timeout(delay)
		setClassName(className)
	}

	useEffect(() => {
		if (props.visible) {
			setClassName("opacity-100")
		}

		if (props.quiltLoaded) {
			setClassName("opacity-100")
			if (delayHide) {
				delay("opacity-0", 1000)
				delay("hidden", 2000)
			}
		}
	}, [props.quiltLoaded, props.visible])

	return className
}
