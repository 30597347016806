import { MutableRefObject, useRef } from "react"
import { RendererProps } from "../HologramEmbed"
import {
	HOLOGRAM_DEFAULT_QUILT_COLS,
	HOLOGRAM_DEFAULT_QUILT_ROWS,
	HOLOGRAM_DEFAULT_TILE_COUNT,
} from "@/prisma/models"

export interface QuiltProps {
	rendererProps: RendererProps
	quiltImageUrl: string
	setView: MutableRefObject<Function>
}

export function HTMLQuilt({ rendererProps, quiltImageUrl, setView }: QuiltProps) {
	const layerBottom = useRef<HTMLDivElement>(null)
	const layerTop = useRef<HTMLDivElement>(null)

	if (!rendererProps.hologram) {
		return <></>
	}

	let {
		quiltTileCount = HOLOGRAM_DEFAULT_TILE_COUNT,
		quiltRows = HOLOGRAM_DEFAULT_QUILT_ROWS,
		quiltCols = HOLOGRAM_DEFAULT_QUILT_COLS,
	} = rendererProps.hologram
	if (!quiltTileCount && quiltRows && quiltCols) {
		quiltTileCount = quiltRows * quiltCols
	}

	if (!quiltTileCount || !quiltRows || !quiltCols) {
		throw new Error("Hologram must have quiltTileCount, quiltRows, and quiltCols")
	}

	// transforms the background css of the quilt divs to show a filtered view
	setView.current = (viewValue: number) => {
		if (!quiltTileCount || !quiltRows || !quiltCols) return
		const clampedInvertedView = Math.min(Math.max(1 - viewValue, 0), 1)
		// get the first view as an integer value between [0, total views)
		const currentViewBottom = Math.floor(clampedInvertedView * (quiltTileCount - 1))
		// get the second view as one plus the first view, clamped to be between [0, total views)
		const currentViewTop = Math.min(currentViewBottom + 1, quiltTileCount - 1)
		// x and y based on the tiling settings
		const viewCoordsBottom = [
			currentViewBottom % quiltCols,
			quiltRows - 1 - Math.floor(currentViewBottom / quiltCols),
		]
		const viewCoordsTop = [currentViewTop % quiltCols, quiltRows - 1 - Math.floor(currentViewTop / quiltCols)]
		const opacity = clampedInvertedView * (quiltTileCount - 1) - currentViewBottom

		function getStyle(viewCoords, opacity) {
			return (
				`width: calc(100% * ${quiltCols});` +
				`height: calc(100% * ${quiltRows});` +
				`background-image: url("${quiltImageUrl}");` +
				`background-size: 100% 100%;` +
				`transform: translate(` +
				`calc(-100% / ${quiltCols} * ${viewCoords[0]}),` +
				`calc(-100% / ${quiltRows} * ${viewCoords[1]})` +
				`);` +
				`opacity: ${opacity};`
			)
		}

		layerBottom.current?.setAttribute("style", getStyle(viewCoordsBottom, 1))
		layerTop.current?.setAttribute("style", getStyle(viewCoordsTop, opacity))
	}

	return (
		<>
			<div ref={layerBottom} className="absolute left-0 top-0" />
			{rendererProps.viewBlending && <div ref={layerTop} className="absolute left-0 top-0" />}
		</>
	)
}
