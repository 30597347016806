export const IS_PRODUCTION = process.env.NODE_ENV == "production" || process.env.VERCEL_ENV == "production"

export const IS_STAGING =
	process.env.VERCEL_ENV == "preview" && process.env.BASE_URL == "https://staging.blocks.glass"

export const IS_VERCEL_PREVIEW = process.env.VERCEL_ENV == "preview" && process.env.COMMIT_REF != "main"

export const IS_LOCAL_DEV = process.env.VERCEL_ENV == "development"

export const IS_DEV = IS_LOCAL_DEV || IS_VERCEL_PREVIEW

export const IS_GITHUB_ACTION = !!process.env.IS_GITHUB_ACTION
