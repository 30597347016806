import { Prisma } from "@prisma/client"
import { MutableRefObject } from "react"

export const EmbedUserSelection: Prisma.UserFindManyArgs["select"] = {
	id: true,
	uuid: true,
	displayName: true,
	username: true,
	avatar: { select: { url: true } },
}

export const EmbedImageAssetSelection: Prisma.ImageAssetFindManyArgs["select"] = {
	id: true,
	url: true,
	width: true,
	height: true,
	type: true,
	kind: true,
	fileSize: true,
	createdAt: true,
	updatedAt: true,
	versionHash: true,
}

export const EmbedHologramInclusion = {
	user: {
		select: { id: true, uuid: true, displayName: true, username: true, avatar: { select: { url: true } } },
	},
	imageAssets: {
		select: {
			id: true,
			url: true,
			width: true,
			height: true,
			type: true,
			kind: true,
			fileSize: true,
			createdAt: true,
			updatedAt: true,
			versionHash: true,
		},
	},
	Settings: true,
}

export type EmbedImageAsset = Prisma.ImageAssetGetPayload<{
	select: {
		id: true
		url: true
		width: true
		height: true
		type: true
		kind: true
		fileSize: true
		createdAt: true
		updatedAt: true
		versionHash: true
	}
}>

export type EmbedHologram = Prisma.HologramGetPayload<{
	omit: { appId: true; canAddToPlaylist: true; isShadowBan: true; totalViews7d: true; totalViews30d: true }
	include: {
		user: {
			select: {
				id: true
				uuid: true
				displayName: true
				username: true
				avatar: { select: { url: true } }
			}
		}
		imageAssets: {
			select: {
				id: true
				url: true
				width: true
				height: true
				type: true
				kind: true
				fileSize: true
				createdAt: true
				updatedAt: true
				versionHash: true
			}
		}
	}
}>

export interface EmbedHologramProps {
	hologram: EmbedHologram | null
	/** Show a dropshadow below the hologram (default=true) */
	dropshadow?: boolean
	/** Do the bootup wiggle
	 * @default true
	 **/
	wiggle?: boolean
	/**
	 * Do a constant, wiggling animation until the user interacts with the hologram
	 * @default false
	 */
	animate?: boolean
	/**
	 * Physicaly tilt the frame of the hologram
	 * @default true
	 */
	frameTilt?: boolean
	/** Viewcone for the hologram (angle from leftmost view to rightmost view) */
	viewcone?: number
	/** Hide the hologram if it's out of view (default=true) */
	enableIntersectionObserver?: boolean
	/** Turns on WebGL/THREE renderer. Turn it off to show the HTML view (default=false) */
	webgl?: boolean
	/** Automatically display on the connected Looking Glass device (default=false) */
	enableOnLookingGlass?: boolean
	/** Check for VR support (default=false) */
	enableVR?: boolean
	/**
	 * Send the current hologram angle to the global zustand store
	 * @see useStore
	 * @default false
	 * @example
	 * ```tsx
	 * const curHologramAngle = useStore((state: AppState) => state.curHologramAngle)
	 * ```
	 **/
	enableAngleStore?: boolean
	/** Override CSS classes */
	className?: string
	/** When true, disables any scaling compensation to avoid clipping. (default=false) */
	fullscreen?: boolean
	/** Specify that mouse movements are controlled by a different container. Useful
	 * for increasing the movement area of a hologram.
	 */
	wiggleContainerRef?: MutableRefObject<any> | null
	/** Show the title of the hologram (default=false) */
	showTitle?: boolean
	/** Show the creator of the hologram (default=false) */
	showCreator?: boolean
	/**
	 * Toggle smoothing out views
	 * @default true
	 */
	viewBlending?: boolean
	/** When enabled, loading this embed will be tracked as a view
	 * @default true
	 */
	analytics?: boolean
	isEditMode?: boolean
	setFocus?: (focus: number) => void
	overrideAspect?: number
	depthOverrideURL?: string
}
