export function getS3Key(url: string) {
	try {
		const pathname = new URL(url).pathname
		const key = pathname.replace(`/${process.env.NEXT_AWS_S3_BUCKET_NAME}/`, "")
		return key
	} catch (e) {
		console.error(e)
		return url
	}
}
