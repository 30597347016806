import { PlaylistItem } from "@prisma/client"
import HologramGallery from "components/HologramGallery"
import Banner from "components/home/Banner"
import HeroImageToHologram from "components/home/HeroImageToHologram"
import { StartHologramming } from "components/home/StartHologramming"
import { IS_LOCAL_DEV, IS_VERCEL_PREVIEW } from "lib/environment"
import { ssgInit } from "lib/trpc/ssg-init"
import { trpc } from "lib/trpc/trpc"
import moment from "moment"
import Image from "next/image"

const getRandomPlaylistItem = (items: PlaylistItem[]) => {
	const length = items.length
	const index = Math.floor(Math.random() * (length - 1))
	return items[index]
}

const heroPlaylistID = IS_LOCAL_DEV ? 12 : 76 // https://blocks.glass/playlists/76
const staffPickPlaylistID = IS_LOCAL_DEV ? 13 : IS_VERCEL_PREVIEW ? 3842 : 75 // https://blocks.glass/playlists/75
const i2hPlaylistID = IS_LOCAL_DEV ? 14 : 77 // https://blocks.glass/playlists/77

export async function getStaticProps() {
	const ssg = await ssgInit()
	const heroPlaylist = await ssg.playlist.getPlaylist.fetch({ id: heroPlaylistID })
	const i2hPlaylist = await ssg.playlist.getPlaylist.fetch({ id: i2hPlaylistID })

	ssg.queryClient.clear()

	const heroID = getRandomPlaylistItem(heroPlaylist!.playlistItems).hologramId.toString()
	const depthID = getRandomPlaylistItem(i2hPlaylist!.playlistItems).hologramId.toString()

	await ssg.hologram.getById.prefetch(heroID)
	await ssg.hologram.getById.prefetch(depthID)
	await ssg.playlist.getPlaylistFeed.prefetchInfinite({ id: staffPickPlaylistID })

	return {
		props: {
			heroID,
			depthID,
			trpcState: ssg.dehydrate(),
		},
		revalidate: moment.duration(3, "days").asSeconds(),
	}
}

export default function Home({ heroID, depthID }: { heroID: string; depthID: string }) {
	const { data: hero } = trpc.hologram.getById.useQuery(heroID)
	const { data: rgbd } = trpc.hologram.getById.useQuery(depthID)
	const {
		data: staffPicks,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = trpc.playlist.getPlaylistFeed.useInfiniteQuery(
		{ id: staffPickPlaylistID },
		{ enabled: !!staffPickPlaylistID, getNextPageParam: (lastPage) => lastPage.nextCursor },
	)

	const staffPickHolograms = staffPicks?.pages?.flatMap((page) =>
		page.playlistItems.map((item) => item.hologram),
	)

	return (
		<>
			{hero && <Banner hologram={hero} />}

			{rgbd && <HeroImageToHologram heroHologram={rgbd} />}

			<div className="mx-auto bg-black pt-16">
				<div className="bg-text holo5 relative mx-auto max-w-3xl pb-20 text-center font-rubik text-4xl font-black md:text-5xl">
					<Image
						src="/home/bubbles.svg"
						width={179}
						height={170}
						alt=""
						className={`floating-widget absolute right-0 top-[-120px] w-[130px] md:-top-40 md:w-[179px]`}
					/>
					Discover holograms from
					<br /> creators like you.
				</div>
			</div>

			{staffPickHolograms && (
				<div className="w-full">
					<HologramGallery
						holograms={staffPickHolograms}
						fetchNextPage={fetchNextPage}
						isFetchingNextPage={isFetchingNextPage}
						hasNextPage={hasNextPage}
						card={{ animateOnHover: true, autoplay: false }}
					/>
				</div>
			)}

			<StartHologramming />
		</>
	)
}
