import { PlusIcon } from "@heroicons/react/24/outline"
import { TRPCError } from "@trpc/server"
import { toastErrorStyle, toastSuccessStyle } from "components/toastStyles"
import { usePlaylists } from "hooks/playlists/usePlaylists"
import { trpc } from "lib/trpc/trpc"
import { useCallback, useState } from "react"
import { toast } from "react-hot-toast"
import AddToPlaylistTitle from "./AddToPlaylistTitle"
import { useBlocksUserStore } from "@/hooks/useStore"
interface AddToPlaylistSubMenuArgs {
	hologram: { id: number; title: string }
	onClose?: () => void
	mode: "SINGLE" | "SELECT"
}

export default function AddToPlaylistSubMenu(args: AddToPlaylistSubMenuArgs) {
	const { hologram, mode, onClose } = args
	const { data: playlists } = trpc.playlist.myPlaylists.useQuery()
	const { createPlaylist } = usePlaylists()
	const [pending, setPending] = useState(false)

	const user = useBlocksUserStore((state) => state.dbUser)
	const [selection, setSelection] = useState(new Set<number>())

	const { mutateAsync: addHologramsToPlaylists, isPending } =
		trpc.playlistItem.addHologramsToPlaylists.useMutation()

	const onSelectPlaylist = useCallback(
		(id: number) => {
			if (selection.has(id)) {
				selection.delete(id)
			} else selection.add(id)
			setSelection(new Set(selection))
		},
		[selection],
	)

	const onAddClicked = useCallback(async () => {
		const playlistIds = Array.from(selection.keys())
		try {
			setPending(true)
			await addHologramsToPlaylists({ playlistIds, hologramIds: [hologram.id] })
			toast(`Added Hologram to Playlists!`, toastSuccessStyle)
			setPending(false)
		} catch (e) {
			const error = e as unknown as TRPCError
			toast(error.message, toastErrorStyle)
		}
		onClose?.()
	}, [selection])

	return (
		<div className="flex flex-col gap-y-2">
			{user && (
				<div className="bg-opacity-0 px-6 py-4 hover:bg-dark-purple-100/10 md:py-2 dark:hover:bg-white/10">
					<label
						className="text-md flex cursor-pointer flex-row items-center gap-3"
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							createPlaylist()
						}}>
						<PlusIcon className="w-6 stroke-dark-purple-100 stroke-[3] md:stroke-1 dark:stroke-white" />
						Create playlist
					</label>
				</div>
			)}
			<div className="h-[1px] bg-white opacity-5" />

			{playlists && playlists.length > 0 && (
				<div className="flex h-96 flex-col gap-4 px-4 text-dark-purple-100 md:gap-2 md:px-2 dark:text-white">
					<div className="md:scroll flex flex-col gap-4 overflow-x-hidden md:gap-1 md:overflow-y-auto md:scrollbar-thin md:scrollbar-track-black/0 md:scrollbar-thumb-black/40 md:scrollbar-track-rounded-full md:dark:scrollbar-thumb-white/40">
						{playlists.map((playlist) => (
							<AddToPlaylistTitle
								key={playlist.id}
								id={playlist.id}
								privacy={playlist.privacy}
								title={playlist.title ? playlist.title : "Untitled"}
								selected={selection.has(playlist.id)}
								onSelect={onSelectPlaylist}
								hologram={hologram}
								onClose={onClose}
								mode={mode}
							/>
						))}
						{/* <InfiniteLoader fetchMore={loadNextPage} hasNextPage={hasNextPage} /> */}
					</div>
				</div>
			)}
			<div className="flex flex-row items-center justify-center gap-4 px-4 md:hidden">
				{/* if we're on mobile, we only want this to shorten the modal, not close it entirely */}
				<button
					className="primaryButtonAlt w-full"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						onClose?.()
					}}>
					Cancel
				</button>
				<button
					className="primaryButton w-full"
					onClick={onAddClicked}
					disabled={isPending || pending || selection.size === 0}>
					Add
				</button>
			</div>
		</div>
	)
}
