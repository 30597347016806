import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { Linky } from "components/Linky"
import { UserProfileImage } from "components/UserProfileImage"
import { useBlocksUserStore } from "hooks/useStore"
import { getUserPermalinkPath } from "lib/utils.user"
import { twMerge } from "tailwind-merge"
import { NavigationItem } from "./navigation"
interface MobileUserButtonProps {
	className?: string
	items: NavigationItem[]
	desktop?: boolean
}
export default function MobileUserButton(props: MobileUserButtonProps) {
	const user = useBlocksUserStore((s) => s.dbUser)
	const { items, className, desktop } = props
	const userPath = user ? getUserPermalinkPath(user) : ""

	return (
		<Menu id="user-avatar-btn" as="div" className={"relative flex"}>
			{({ open, close }) => {
				return (
					<>
						<Transition
							enter="transition duration-100 ease-out"
							enterFrom="transform scale-95 opacity-0"
							enterTo="transform scale-100 opacity-100"
							leave="transition duration-75 ease-out"
							leaveFrom="transform scale-100 opacity-100"
							leaveTo="transform scale-95 opacity-0">
							<MenuItems
								className={twMerge(
									"absolute z-20 flex min-w-[10rem] flex-none flex-col overflow-hidden whitespace-nowrap rounded-md bg-gradient-to-br from-[#6A68A8] to-[#594386] shadow-md",
									desktop
										? "lkg-container-modal-solid absolute top-[45px] z-10 flex origin-top -translate-x-1/2 flex-col justify-start gap-4 whitespace-nowrap p-3 outline-none"
										: "bottom-12 left-0",
								)}>
								{items.map((item) => (
									<MenuItem
										className={twMerge(
											"group flex justify-around gap-4 rounded-lg !bg-opacity-5 p-3 transition duration-150 ease-in-out hover:cursor-pointer hover:bg-black hover:dark:bg-white",
										)}
										as={Linky}
										href={item.name === "Profile" ? userPath : item.href}
										key={`user-${item.name}-btn`}>
										<div className="flex flex-grow flex-col gap-1">
											<p
												className={
													"hover:-text-gray-900 text-base font-medium text-gray-900 transition dark:text-white dark:group-hover:text-white"
												}>
												{item.name}
											</p>
										</div>
									</MenuItem>
								))}
							</MenuItems>
						</Transition>
						<MenuButton>
							<UserProfileImage
								className={twMerge(
									className!,
									open ? "ring-2 ring-white ring-offset-2 ring-offset-gray-800" : "",
								)}
								user={user}
							/>
						</MenuButton>
					</>
				)
			}}
		</Menu>
	)
}
