import { Menu, MenuButton } from "@headlessui/react"
import { useBannerStore } from "hooks/useBannerStore"
import { useCastStore } from "hooks/useCastStore"
import { useColorScheme } from "hooks/useColorScheme"
import { useHoloPlayCore } from "hooks/useHoloPlayCore"
import { gtmEvent } from "lib/analytics"
import { classNames } from "lib/classNames"
import { useRouter } from "next/router"
import { useEffect, useRef } from "react"
import styles from "./HologramCastButton.module.css"
import CastIcon from "./icons/CastIcon"
import SpinnerLoader from "./SpinnerLoader"

interface Args {
	forceDarkMode?: boolean
}

export function NavCastButton({ forceDarkMode = false }: Args) {
	const connection = useCastStore((store) => store.connection)
	const castStatus = useCastStore((store) => store.castStatus)
	const hologram = useCastStore((store) => store.hologram)
	const isOpen = useBannerStore((store) => store.isOpen)
	const setOpen = useBannerStore((store) => store.setOpen)
	const handleCastHologram = useCastStore((store) => store.handleCastHologram)
	const manuallyConnectToBridge = useCastStore((store) => store.manuallyConnectToBridge)
	const { wipeFromLookingGlass } = useHoloPlayCore()

	const castIconRef = useRef<SVGSVGElement>(null)
	const isDarkMode = useColorScheme()
	const router = useRouter()

	const { holo_id } = router.query

	async function onClick() {
		gtmEvent("cast-connect-btn")
	}

	useEffect(() => {
		// is forceDarkMode is enabled defer to tailwind, otherwise override the color
		if (forceDarkMode && connection !== "CONNECTED") {
			return
		}
		//we're in dark mode and the cast store is connected
		if (connection === "CONNECTED") {
			castIconRef.current?.classList?.remove("fill-black")
			castIconRef.current?.classList?.remove("fill-white")
			castIconRef.current?.classList?.add("fill-green-400")
			// if we're not connected, set the styling for light and dark mode
		} else {
			castIconRef.current?.classList?.remove("fill-green-400")
			if (isDarkMode) {
				castIconRef.current?.classList?.add("fill-white")
				castIconRef.current?.classList?.remove("fill-black")
			} else {
				castIconRef.current?.classList?.add("fill-black")
				castIconRef.current?.classList?.remove("fill-white")
			}
		}
	}, [])

	let tooltipContent = "Connect to Looking Glass"
	if (connection === "PENDING") tooltipContent = "Connecting..."
	if (connection === "CONNECTED") tooltipContent = "Connected!"
	if (connection === "CONNECTED" && castStatus === "ACTIVE") tooltipContent = "Stop casting"

	return (
		// the key here is to force the component to re-render when the route changes
		<Menu as="div" className="relative ml-3 hidden sm:inline" key={router.asPath}>
			<div id="nav-connect-device-btn" onClick={onClick}>
				<MenuButton
					className="flex h-8 w-8 place-content-center place-items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
					onClick={async () => {
						// if we're casting a hologram we want this to remove the hologram from the display
						if (connection === "CONNECTED" && parseInt(holo_id as string) === hologram?.id) {
							wipeFromLookingGlass()
							return
							// if we're not connected to bridge, try to manually connect
						} else if (connection !== "CONNECTED") {
							gtmEvent("cast-connect-btn")
							manuallyConnectToBridge()
							// if we're connected to bridge, we want this to just toggle the banner.
						} else if (connection === "CONNECTED" && holo_id === undefined) {
							setOpen(!isOpen)
						}

						if (holo_id) {
							const id = parseInt(holo_id as string)

							await handleCastHologram(id, undefined)
						}
					}}>
					<span className="sr-only">Connect to Looking Glass</span>
					<div
						className={classNames(
							"relative h-11 w-11 rounded-full p-3",
							castStatus === "ACTIVE" ? styles.pulse : "",
						)}>
						<CastIcon
							reference={castIconRef}
							fillColor={connection === "CONNECTED" ? "fill-green-400" : "fill-black dark:fill-white"}
							className={classNames("w-5 transition-all")}
							animate={castStatus === "PENDING_HOLOGRAM" || castStatus === "PENDING_PLAYLIST"}
						/>
						{(castStatus === "PENDING_HOLOGRAM" || castStatus === "PENDING_PLAYLIST") && (
							<SpinnerLoader
								className="absolute inset-0 m-auto -translate-x-[3px]"
								color={"#E649F5"}
								speed={400}
								thickness={100}
							/>
						)}
					</div>
				</MenuButton>
			</div>
		</Menu>
	)
}
