import {
	allEventsResponse,
	BridgeClient,
	Display,
	HologramType,
	monitorConnectResponse,
	newItemPlayingResponse,
} from "@lookingglass/bridge"
import { toastErrorStyle } from "components/toastStyles"
import { PrismaToBridgeDepth, PrismaToBridgeQuilt } from "lib/bridge"
import { Client } from "lib/holoplaycore.module"
import { toast } from "react-hot-toast"
import { trpcClient } from "server/client"
import { GetCastHologramResult } from "server/routers/hologram.trpc"
import { GetPlaylistResult } from "server/routers/playlist/playlist.trpc"
import { z } from "zod"
import { create } from "zustand"
import { useBannerStore } from "./useBannerStore"
import { findValidAspectRatio } from "@/lib/displays"

const LOOKING_GLASS_DISPLAY_PREFIX = "LKG-"

type allEventsResponse = z.infer<typeof allEventsResponse>
type monitorConnectResponse = z.infer<typeof monitorConnectResponse>
type newItemPlayingResponse = z.infer<typeof newItemPlayingResponse>

export type BridgeVersion = {
	major: number
	minor: number
	patch?: number
	hotfix?: number
}

export type ConnectionType = "NOT_ESTABLISHED" | "PENDING" | "CONNECTED" | "DISCONNECTED" | "ERROR"

export type CastType = "HOLOGRAM" | "PLAYLIST"

export type CastStatus = "ACTIVE" | "PENDING_HOLOGRAM" | "PENDING_PLAYLIST" | "ERROR" | "INACTIVE"

export interface CastState {
	holoplayClient: Client | null
	setHoloPlayClient: (client: Client | null) => void

	bridgeClient: BridgeClient

	attempts: number
	fails: number
	connection: ConnectionType
	displays: Display[]
	castStatus: CastStatus
	isError: boolean
	hologram?: GetCastHologramResult
	playlist?: GetPlaylistResult
	playlistItemId?: number
	/**perform the actions necessary to cast a hologram */
	handleCastHologram: (hologramId: number, uuid?: string, playlistItemId?: number) => Promise<void>
	/**for cases where we want to automatically cast, but not trigger the banner if we fail. */
	handleAutomaticCast: (hologramId: number, uuid?: string) => Promise<void>
	/**perform the actions ncessary to cast a playlist */
	castPlaylist: (args: { id: number; uuid?: string; index?: number }) => Promise<void>
	/**manually check if our display is plugged back in */
	handleRetryDisplay: () => Promise<void>
	/**update the state when we get a new item playing event */
	handleNewItemPlaying: (event: newItemPlayingResponse) => void
	/**handle updating the state when the Looking Glass is disconnected */
	handleMonitorDisconnect: (event: monitorConnectResponse) => void
	handleMonitorConnect: (event: monitorConnectResponse) => void
	checkDepthGenerationProgress: (id: number) => Promise<void>

	version: BridgeVersion
	setVersion: (version: BridgeVersion) => void

	/**handle disconnecting from bridge */
	onDisconnect: () => void

	onConnect: () => void

	/**connect to bridge and handle setting up initial state reconciliation */
	handleConnectToBridge: () => Promise<void>
	/**manually connect to bridge */
	manuallyConnectToBridge: () => Promise<void>
	/**handle parsing the event stream from when we connect to bridge */
	handleAllEventStream: (event: allEventsResponse, events: allEventsResponse[]) => void
	/**state for when we recieved an error */
	onError: () => void
	/**state for when the RGBD hologram is still generating */
	onGenerating: () => void
	/**state of the cast, either hologram or playlist */
	onCastPending: () => void
	/**display the banner when bridge is outdated, ie RGBD or Playlists */
	onOutdated: (version: BridgeVersion) => void
	/**stop the current cast */
	onCastTerminated: () => void
	/**state for a successful cast */
	onCastSuccess: (hologram: GetCastHologramResult, playlistItemId?: number) => void
	/**handle state for no display */
	onNoDisplay: () => void
	/**pending state for manually retrying to connect display */
	onRetryDisplay: () => void

	togglePlayPause: () => void
	transportNext: () => void
	transportPrevious: () => void

	playState: BridgeClient["playState"]
	setPlayState: (state: BridgeClient["playState"]) => void
	isPlaying: boolean
}

export const useCastStore = create<CastState>((set, get) => ({
	attempts: 0,
	fails: 0,
	displays: [],
	connection: "NOT_ESTABLISHED",
	castStatus: "INACTIVE",
	isError: false,
	hologram: undefined,
	version: { major: 0, minor: 0 },

	holoplayClient: null,
	setHoloPlayClient: (value) =>
		set((state) => ({
			holoplayClient: value,
		})),

	bridgeClient: BridgeClient.getInstance(),

	playState: "STOPPED",

	setPlayState: (playState) => {
		const isPlaying = playState === "PLAYING"
		set((state) => ({ ...state, playState, isPlaying }))
	},

	isPlaying: false,

	checkDepthGenerationProgress: async (id) => {
		const { handleCastHologram } = get()
		const checkAndUpdate = async () => {
			const progress = await trpcClient.hologram.checkRGBDGenerationProgress.query({
				id: id,
			})

			if (!progress) {
				return
			}

			if (progress === 100) {
				await handleCastHologram(id, undefined)
			}

			if (progress < 100) {
				setTimeout(checkAndUpdate, 1000) // Wait for 1 second before checking again
			}
		}

		checkAndUpdate()
	},

	castPlaylist: async ({ id, uuid, index = 0 }) => {
		const { bridgeClient, onOutdated, onError, onNoDisplay } = get()
		let status

		try {
			status = await bridgeClient.status()

			if (status === false) {
				throw new Error("false!")
			}
		} catch (error) {
			console.error("Bridge is unavailable", error)
			onError()
			return
		}

		set((state) => ({
			...state,
			castStatus: "PENDING_PLAYLIST",
			playState: "PAUSED",
		}))

		await new Promise((resolve) => setTimeout(resolve, 300))

		if (status === false) {
			onError()
			return
		}

		const displays = await bridgeClient.getDisplays()

		if (!displays || displays?.response?.length === 0) {
			onNoDisplay()
			return
		}

		const bridgeVersion = await bridgeClient.getVersion()

		const version = bridgeVersion.response

		/**we should get this back, otherwise something is very wrong */
		if (bridgeVersion.success === false) {
			new Error("Failed to get version")
			onError()
		}

		/**if the user is running bridge less than 2.4.4 we don't have tags.
		 * we use tags to keep track of state from Bridge
		 *
		 * we could in theory use an older version of Bridge, but it'd
		 * create weird state in Blocks.
		 */

		if (version.major < 2 && version.minor < 4) {
			new Error("Bridge version is too old to play playlist")
			onOutdated(version)
			return
		}

		let playlist = await trpcClient.playlist.getPlaylist.query({ id, uuid })

		if (!playlist) {
			return
		}

		if (!playlist) {
			new Error("Playlist Not Found")
			onError()
		}

		const { playlistItems } = playlist

		const hologramsToCast: HologramType[] = playlistItems
			.map((item) => {
				const { hologram } = item
				const { imageAssets, type } = hologram

				if (type === "QUILT") {
					const quilt = PrismaToBridgeQuilt({
						hologram,
						playlistId: id,
						playlistUUID: uuid,
						playlistItemId: item.id,
					})

					if (!quilt) {
						toast("Oh no! Bridge isn't able to play this" + hologram.id, toastErrorStyle)
						//skip this item
						return
					}

					return quilt
				} else if (type === "RGBD") {
					const depthGram = PrismaToBridgeDepth({
						hologram,
						playlistId: id,
						playlistUUID: uuid,
						playlistItemId: item.id,
					})

					if (!depthGram) {
						toast("Oh no! Bridge isn't able to play this" + hologram.id, toastErrorStyle)
						//skip this item
						return
					}

					return depthGram
				}
			})
			// Filter out possible undefineds
			.filter((item): item is HologramType => !!item)

		const { success } = await bridgeClient.playRemotePlaylist(hologramsToCast, index)

		if (!success) {
			throw new Error("Bridge failed to play playlist ID " + playlist.id)
		} else {
			set((state) => ({
				...state,
				playlist: playlist,
				castStatus: "ACTIVE",
				playState: "PLAYING",
				hologram: playlistItems[0].hologram,
				playlistItemId: playlistItems[0].id,
			}))
		}
	},

	togglePlayPause: async () => {
		const { bridgeClient, playState } = get()
		if (bridgeClient.isConnected) {
			if (playState === "PAUSED" || playState === "STOPPED") {
				const { response } = await bridgeClient.play()
				if (!response) {
					throw new Error("Error in togglePlayPause", { cause: response })
				}
				set((state) => ({ ...state, playState: "PLAYING" }))
			} else {
				const { response } = await bridgeClient.pause()
				if (!response) {
					throw new Error("Error in togglePlayPause", { cause: response })
				}
				set((state) => ({ ...state, playState: "PAUSED" }))
			}
		}
	},

	transportNext: async () => {
		const { bridgeClient } = get()
		if (bridgeClient.isConnected) {
			const { response } = await bridgeClient.next()
			if (!response) {
				throw new Error("Failed to transport next")
			}
		}
	},

	transportPrevious: async () => {
		const { bridgeClient } = get()
		if (bridgeClient.isConnected) {
			const { response } = await bridgeClient.previous()
			if (!response) {
				throw new Error("Failed to transport next")
			}
		}
	},

	manuallyConnectToBridge: async () => {
		const { bridgeClient, connection, onError, onConnect } = get()

		if (connection === "PENDING" || connection === "CONNECTED") {
			console.warn("already connected!")
			return
		}

		const status = await bridgeClient.status()

		if (!status) {
			onError()
		} else {
			const bridgeConnection = await bridgeClient.connect()

			const version = bridgeConnection.response.version

			/**connection failed */
			if (!bridgeConnection.success) {
				set((state) => ({
					...state,
					connection: "ERROR",
					isError: true,
					hologram: undefined,
					version: { major: 0, minor: 0 },
				}))
			}

			onConnect()
		}
	},

	/**This function handles the initial conenction to bridge when the page loads
	 * It should read the events from the "all events" listener
	 * and update the necessary state
	 *
	 */
	handleConnectToBridge: async () => {
		const { bridgeClient, connection, handleAllEventStream, onNoDisplay } = get()

		if (connection === "PENDING" || connection === "CONNECTED") {
			console.warn("ALREADY ATTEMPTING CONNECTION")
			return
		}

		set((state) => ({ ...state, connection: "PENDING" }))

		const status = await bridgeClient.status()

		if (!status) {
			set((state) => ({
				...state,
				connection: "NOT_ESTABLISHED",
				isError: true,
				hologram: undefined,
				version: { major: 0, minor: 0 },
			}))
			return
		}

		bridgeClient.setVerbosity(0)

		const events: allEventsResponse[] = []

		const eventHandler = (event) => {
			handleAllEventStream(event, events)
		}

		bridgeClient.addEventListener("All Events", eventHandler)

		// wait around half a second, then remove the event listener

		const bridgeConnection = await bridgeClient.connect()

		const version = bridgeConnection.response.version

		/**connection failed */
		if (!bridgeConnection.success) {
			set((state) => ({
				...state,
				connection: "ERROR",
				isError: true,
				hologram: undefined,
				version: { major: 0, minor: 0 },
			}))

			await bridgeClient.removeEventListener("All Events", eventHandler)
			return
		}
		// if our connection succeeds, continue state setup
		// check for displays
		try {
			await bridgeClient
				.getDisplays()
				.then((call) => {
					if (!call.response || call.response.length == 0) {
						// we want to set the state, but not open the banner
						set((state) => ({
							...state,
							connection: "CONNECTED",
							castStatus: "ERROR",
							isError: true,
							displays: [],
							hologram: undefined,
							playlist: undefined,
						}))
					} else {
						const displays =
							call.response.filter((display) =>
								display.calibration?.serial.includes(LOOKING_GLASS_DISPLAY_PREFIX),
							) ?? []

						set((state) => ({
							...state,
							displays: displays,
						}))
					}
				})
				.catch((e) => {
					console.warn(e)
				})
		} catch (e) {
			console.warn(e)
		}

		await bridgeClient.addEventListener("New Item Playing", (event) => {
			get().handleNewItemPlaying(event)
		})

		set((state) => ({
			...state,
			connection: "CONNECTED",
			isError: false,
			version: { major: version.major, minor: version.minor, patch: version.patch, hotfix: version.hotfix },
		}))

		//leave the event listener open for half a second
		await new Promise((resolve) => setTimeout(resolve, 500))

		await bridgeClient.removeEventListener("All Events", eventHandler)

		// we want these events to establish after we've reconciled state

		await bridgeClient.addEventListener("Bridge Disconnected", get().onDisconnect)

		await bridgeClient.addEventListener("Monitor Disconnect", (event) =>
			//@ts-ignore - bridge.js type merge for events
			get().handleMonitorDisconnect(event as z.infer<typeof monitorConnectResponse>),
		)

		await bridgeClient.addEventListener("Monitor Connect", (event) =>
			get().handleMonitorConnect(event as z.infer<typeof monitorConnectResponse>),
		)
	},

	handleAllEventStream: async (event: allEventsResponse, events: allEventsResponse[]) => {
		if (event.payload.value.event.value === "Progress Update") {
			return
		}

		/**these two events will depend on when we get them back from bridge,
		 * we handle both here so the state should resolve itself
		 * */
		if (event.payload.value.event.value === "Transport Control Play") {
			set((state) => ({ ...state, playState: "PLAYING" }))
		}

		if (event.payload.value.event.value === "Transport Control Pause") {
			set((state) => ({ ...state, playState: "PAUSED" }))
		}

		/**arguably the most important part of state reconciliation
		 * we use the tag feature to add extra data to the cast payload
		 * we then get that data back and parse it.
		 */
		if (event.payload.value.event.value === "New Item Playing") {
			//@ts-ignore - bridge.js type merge for events
			// tag only exists on one event, it's trying to resolve for all
			const tag = event.payload.value.tag.value.split("\\").join("")

			try {
				const { setIsOwner, setOpen, setState } = useBannerStore.getState()

				// attempt to parse json from the tag
				if (tag === "") {
					throw new Error("No tag")
				}

				const item = JSON.parse(tag)

				if (!item.id) {
					throw new Error("No ID")
				}

				// handle reconstructing the hologram
				if (item.id && !item.playlistId) {
					set((state) => ({
						...state,
						playlist: undefined,
						hologram: undefined,
						castStatus: "PENDING_HOLOGRAM",
					}))

					const hologram = await trpcClient.hologram.getCastHologram.query({
						id: item.id,
						uuid: item.hologramUUID,
						productAspect: item.productAspect,
					})

					if (!hologram) {
						throw new Error("Hologram not found")
					}

					set((state) => ({
						...state,
						connection: "CONNECTED",
						playlist: undefined,
						hologram: hologram,
						castStatus: "ACTIVE",
					}))

					//update the banner
					setIsOwner(true)
					setOpen(true)
					setState("connected")

					return
				}

				// handle reconstructing the playlist
				if (item.playlistId) {
					// update the state so the cast is pending
					set((state) => ({
						...state,
						connection: "CONNECTED",
						playlist: undefined,
						hologram: undefined,
						castStatus: "PENDING_PLAYLIST",
					}))

					// fetch the playlist based on the ID we recieve from Bridge
					const playlist = await trpcClient.playlist.getPlaylist.query({
						id: item.playlistId,
						uuid: item.playlistUUID,
					})

					if (!playlist) {
						throw new Error("Playlist not found")
					}
					// find the hologram with id of item.id in the playlist

					const playlistItem = playlist.playlistItems.find(
						(playlistItem) => playlistItem.hologram.id === item.id,
					)

					const hologram = playlistItem?.hologram ?? undefined

					// set the state!
					set((state) => ({
						...state,
						connection: "CONNECTED",
						playlist: playlist,
						hologram: hologram,
						castStatus: "ACTIVE",
						isCastPlaylistPending: false,
					}))

					//update the banner
					setIsOwner(true)
					setOpen(true)
					setState("connected")
				}
			} catch (e) {
				// tag or parts of tag were invalid
				console.error("Error parsing tag", e)
				set((state) => ({
					...state,
					connection: "CONNECTED",
					playlist: undefined,
					hologram: undefined,
					castStatus: "INACTIVE",
				}))
			}
		}

		events.push(event)
	},

	/**generally we should automatically detect the display being reconnected
	 * but there may be some cases where manually checking is helpful
	 */
	handleRetryDisplay: async () => {
		// we want to reset the state to show the user the button is doing something

		const { bridgeClient, onNoDisplay, onRetryDisplay, onConnect } = get()

		onRetryDisplay()

		const displays = await bridgeClient.getDisplays()

		await new Promise((resolve) => setTimeout(resolve, 1000))

		if (displays.response?.length === 0) {
			onNoDisplay()
		} else {
			onConnect()
		}
	},

	/**cast a single hologram to bridge */
	handleCastHologram: async (hologramId: number, uuid?: string, playlistItemId?: number) => {
		const {
			bridgeClient,
			onNoDisplay,
			onError,
			onCastSuccess,
			onCastPending,
			onGenerating,
			checkDepthGenerationProgress,
		} = get()

		if (get().castStatus === "PENDING_HOLOGRAM") return

		onCastPending()

		bridgeClient.setVerbosity(0)

		let status

		try {
			status = await bridgeClient.status()
			if (status === false) {
				onError()
			}
		} catch (error) {
			console.error(error)
			onError()
			return
		}

		const displays = await bridgeClient.getDisplays()

		if (displays.response?.length === 0) {
			onNoDisplay()
			return
		}

		const display = displays.response?.[0] as Display

		// if our display doesn't have calibration data, exit
		if (!display.calibration) {
			onError()
			return
		}

		const height = display.calibration.screenH
		const width = display.calibration.screenW

		const displayAspect = width / height

		const knownAspect = findValidAspectRatio(displayAspect) ?? undefined

		if (bridgeClient.isConnected) {
			const hologram = await trpcClient.hologram.getCastHologram.query({
				id: hologramId,
				uuid: uuid,
				productAspect: knownAspect,
			})

			if (!hologram) {
				throw new Error("Hologram not found")
			}

			const { type } = hologram

			if (type === "QUILT") {
				const quilt = PrismaToBridgeQuilt({
					hologram,
					playlistId: 0,
					playlistUUID: undefined,
					playlistItemId: playlistItemId ?? 0,
				})

				if (!quilt) {
					toast("Looks like this hologram isn't casting, send us a message", toastErrorStyle)
					onError()
					return
				}

				const cast = await bridgeClient.cast(quilt)

				if (!cast.success) {
					console.warn({ cast })
					// onError()
				}

				onCastSuccess(hologram, playlistItemId)
			} else if (type === "RGBD") {
				const depthGram = PrismaToBridgeDepth({
					hologram,
					playlistId: 0,
					playlistUUID: undefined,
					playlistItemId: playlistItemId ?? 0,
				})

				if (!depthGram) {
					onGenerating()
					await trpcClient.hologram.generateRGBD.mutate({ id: hologramId })
					await checkDepthGenerationProgress(hologramId)
					return
				}

				const cast = await bridgeClient.cast(depthGram)

				if (!cast.success) {
					console.warn({ cast })
					// onError()
					return
				}

				onCastSuccess(hologram, playlistItemId)
			}
		}
	},

	handleAutomaticCast: async (hologramId: number, uuid?: string) => {
		const { handleCastHologram, bridgeClient } = get()
		if (!hologramId) return
		const status = await bridgeClient.status()
		let display: Display[] | null
		try {
			const displays = await bridgeClient.getDisplays()
			display = displays.response ?? []
			if (status && display.length >= 1) {
				await handleCastHologram(hologramId, uuid ?? undefined)
			}
		} catch (e) {
			return
		}
	},

	handleMonitorDisconnect: (e) => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		// if it's a normal monitor, don't do anything
		if (!e.payload.value.name.value.includes(LOOKING_GLASS_DISPLAY_PREFIX)) return

		setIsOwner(false)
		setOpen(true)
		setState("no_display")

		set((state) => ({
			...state,
			castStatus: "ERROR",
			displays: [],
			isError: true,
			hologram: undefined,
			version: { major: 0, minor: 0 },
		}))
	},

	handleMonitorConnect: async (e) => {
		const { bridgeClient } = get()
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		// if it's a normal monitor, don't do anything
		if (!e.payload.value.name.value.includes(LOOKING_GLASS_DISPLAY_PREFIX)) return

		//bridge's payloads don't match so we'll need to getDisplays anyways

		const check = await bridgeClient.getDisplays()
		const bridgeDisplays = check.response

		if (!bridgeDisplays || bridgeDisplays.length === 0) return

		if (
			bridgeDisplays?.filter((display) => display.calibration?.serial.includes(LOOKING_GLASS_DISPLAY_PREFIX))
				.length === 0
		)
			return

		setIsOwner(false)
		setOpen(true)
		setState("connected")

		set((state) => ({
			...state,
			isError: false,
			displays: bridgeDisplays,
		}))
	},

	handleNewItemPlaying: (event: z.infer<typeof newItemPlayingResponse>) => {
		if (!get().playlist) return

		const tag = event.payload.value.tag.value.split("\\").join("")
		const item = JSON.parse(tag)

		const playlistItem = get()
			.playlist?.playlistItems // find the playlist item with the same ID
			.find((playlistItem) => playlistItem.id === item.playlistItemId)

		if (!playlistItem) return

		const { hologram } = playlistItem

		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(true)
		setOpen(true)
		setState("connected")

		set((state) => ({ ...state, hologram: hologram, playlistItemId: playlistItem.id }))
	},

	onConnect: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("connected")

		set((state) => ({
			...state,
			castStatus: "INACTIVE",
			connection: "CONNECTED",
			isError: false,
			hologram: undefined,
		}))
	},

	/**Handle disconnecting from Bridge, usually from a bridge crash or the user closing Bridge */
	onDisconnect: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("disconnected")

		set((state) => ({
			...state,
			castStatus: "INACTIVE",
			connection: "DISCONNECTED",
			isError: true,
			hologram: undefined,
			version: { major: 0, minor: 0 },
		}))
	},

	onGenerating: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("generating")

		set((state) => ({
			...state,
			castStatus: "ERROR",
			connection: "ERROR",
			isError: true,
			hologram: undefined,
			playlist: undefined,
			version: { major: 0, minor: 0 },
		}))
	},

	/**Handle an Error from Bridge */
	onError: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("cant_connect")

		set((state) => ({
			...state,
			castStatus: "ERROR",
			connection: "ERROR",
			isError: true,
			hologram: undefined,
			playlist: undefined,
			version: { major: 0, minor: 0 },
		}))
	},

	/**Pending state, waiting for a cast to complete */
	onCastPending: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("pending")

		set((state) => ({
			...state,
			castStatus: "PENDING_HOLOGRAM",
			isError: false,
			hologram: undefined,
		}))
	},

	onOutdated: (version: BridgeVersion) => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("outdated")

		set((state) => ({
			...state,
			castStatus: "ERROR",
			isError: true,
			hologram: undefined,
			playlist: undefined,
			version: version,
		}))
	},

	onCastTerminated: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(false)
		setState("connected")

		set((state) => ({
			...state,
			connection: "CONNECTED",
			castStatus: "INACTIVE",
			isError: false,
			hologram: undefined,
			playlist: undefined,
			playlistItemId: undefined,
		}))
	},

	onCastSuccess: (hologram: GetCastHologramResult, playlistItemId?: number) => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		const { bridgeClient } = get()

		setIsOwner(true)
		setOpen(true)
		setState("connected")

		set((state) => ({
			...state,
			connection: "CONNECTED",
			castStatus: "ACTIVE",
			isError: false,
			hologram: hologram,
			playlist: undefined,
			playlistItemId: playlistItemId,
		}))
	},

	onNoDisplay: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)
		setState("no_display")

		set((state) => ({
			...state,
			connection: "CONNECTED",
			castStatus: "ERROR",
			isError: true,
			displays: [],
			hologram: undefined,
			playlist: undefined,
		}))
	},

	onRetryDisplay: () => {
		const { setIsOwner, setOpen, setState } = useBannerStore.getState()

		setIsOwner(false)
		setOpen(true)

		set((state) => ({
			...state,
			connection: "CONNECTED",
			castStatus: "INACTIVE",
			isError: false,
			displays: [],
			hologram: undefined,
			playlist: undefined,
		}))
	},

	/**Update the Bridge Version */
	setVersion: (value) =>
		set((state) => ({
			version: value,
		})),
}))
